/* stylelint-disable */
@import './variables';


@mixin gradationColor {
  background: linear-gradient(92.01deg,$color-red 0%, $color-orange 100%);
}

@mixin gradationText {
  -webkit-background-clip: text;
  background-image:  linear-gradient(92.01deg,$color-red 0%, $color-orange 100%);
  color: $color-red;
  -webkit-text-fill-color: transparent;
}

@mixin gradationColorSecondary {
  background: linear-gradient(120.46deg, $color-blue 0%, $color-blue-light 99.64%);
}

@mixin gradationTextSecondary {
  -webkit-background-clip: text;
  background-image:  linear-gradient(92.01deg, $color-blue 0%, $color-blue-light  100%);
  color: $color-blue;
  -webkit-text-fill-color: transparent;
}


@mixin panelBackGround {
  background: $color-white;
  border: 1px solid $color-white-dark;
  border-radius: 16px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}


@mixin container {
  background: $color-white;
  box-sizing: border-box;
  padding: 72px 16px 24px;
  max-width: 768px;
  margin: auto;
}

@mixin containerWraper {
  background: $color-white;
  box-sizing: border-box;
  padding-bottom: 24px;
  padding-top: 48px;
  max-width: 768px;
  margin: auto;
}

@mixin contentWrap {
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}


@mixin sectionWrap {
  box-sizing: border-box;
  padding-bottom: 24px;
  padding-top: 24px;
}

