@import '../../scssUtils/variables';

.TitleBase {
}

.TitlePrimary{
  font-size: 2rem;
  font-weight: bold;
}

.TitleSecondary{

}
