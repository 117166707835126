@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.chatWrap{
  @include container;
  background-color: $color-gray-light;
  min-height: 100vh;
  padding-top: 55px;
  padding-bottom: 100px;
}

.withIcon{
  margin-top: 16px;
}

.myself{
  margin-top: 16px;
  & + .myself{
    margin-top: 5px;
  }
}

.noIcon{
  margin-top: 5px;
}

.unreadMessage{
  margin: 16px auto;
}

.chatInput{
  position: fixed;
  left: 0;
  bottom: 48px;
  width: 100vw;
  border-top: 1px solid $color-gray-dark;
  border-bottom: 1px solid $color-gray-light-ccc;
}
