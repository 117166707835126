@import '../common/scssUtils/mixins';

.container{
  @include container;

  padding-left: 0;
  padding-right: 0;
}

.tabMenuWrap{
  border-bottom: solid 1px $color-white-dark;
  display: flex;
}

.tabMenuItem{
  width: 50%;
}

.title{
  font-size: 15px;
  padding-bottom: 16px;
}

.body{
  padding: 24px 16px;
}

.pool{
  margin-top: 32px;
  text-align: center;
}

.historyLabel{
  margin-left: 8px;
}

.history{
  border-top: 1px solid $color-white-dark;
  margin-top: 8px;
}
