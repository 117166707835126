@import '../../scssUtils/variables';

$rhap_theme-color: #868686 !default;
$rhap_background-color: #fff !default;
$rhap_bar-color: #ddd !default;
$rhap_time-color: #333 !default;
$rhap_font-family: inherit !default;

.rhap_container {
  align-items: center;
  background: $color-white;
  border: 1px solid $color-white-dark;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content:flex-start;
  overflow: hidden;
  padding: 0 24px 0 0;
}

.rhap_main {
  display: flex;
  flex: 1;
}

.rhap_controls-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.rhap_stacked {
  .rhap_controls-section {
    margin-top: 8px;
  }
}

.rhap_horizontal {
  flex-direction: row;

  .rhap_controls-section {
    margin-left: 8px;
  }
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;

  .rhap_controls-section {
    margin-right: 8px;
  }
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;

  .rhap_controls-section {
    margin-bottom: 8px;
  }
}

.rhap_progress-section {
  align-items: center;
  display: flex;
  flex: 1;
}

.rhap_progress-container {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  height: 20px;
  margin-left: calc(10px + 1%);
  -webkit-user-select: none;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_time {
  color: $color-dark-light;
  flex-shrink: 0;
  font-size: 1.2rem;
}

.rhap_progress-bar {
  background-color: $rhap_bar-color;
  border-radius: 2px;
  box-sizing: border-box;
  height: 2px;
  margin-left: 4px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.rhap_progress-filled {
  background: linear-gradient(126.44deg, #eb5757 0%, #ff8d23 100%);
  border-radius: 2px;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.rhap_progress-bar-show-download {
  background-color: rgba($rhap_bar-color, 0.5);
}

.rhap_download-progress {
  background-color: $rhap_bar-color;
  border-radius: 2px;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.rhap_progress-indicator {
  background: $color-white;
  border: 1px solid #eee;
  border-radius: 50%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: block;
  height: 16px;
  left: 50%;
  margin: -8px;
  margin-left: -10px;
  position: absolute;
  top:50%;
  width: 16px;
  z-index: 3;

  &::after{
    background:$color-white-dark;
    height: 2px;
    position: absolute;
    top:22px;
    width: 100%
  }
}

.rhap_main-controls {
  align-items: center;
  justify-content: center;
}

.rhap_play-pause-button {
  font-size: 40px;
  height: 40px;
  width: 40px;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  padding: 0;

  &:hover {
    opacity: 0.9;
    transition-duration: 0.2s;
  }

  &:active {
    opacity: 0.95;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}
