@import '../../scssUtils/variables';

.McsFooterNavBase {
  background: $color-white;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 2px 2px 0;
  width: 100%;
  max-width: 768px;
  margin: auto;

  li{
    margin-bottom: 2px;
    width: calc(50% - 1px);

    &:nth-child(even){
      margin-left: 2px;
    }
  }
}
