@import '../common/scssUtils/mixins';

.OrgFooterBase {
  background: $color-white;
  width: 100%;
}

.OrgFooterBaseWithBanner {
  padding-bottom: 56px;
}

.OrgFooterBaseWithTwitter {
  padding-bottom: 70px;
}

.OrgFooterFixBanner{
  bottom: 0;
  height: 56px;
  position: fixed;
  width: 100%;
  z-index: 998;
}

.OrgFooterFixBannerDummy{
  height: 56px;
}

.OrgFooterFixPrimaryButton{
  background-color: $color-dark;
  bottom: 0;
  box-sizing: border-box;
  height: 56px;
  padding: 4px;
  position: fixed;
  width: 100%;
  z-index: 998;
  cursor: pointer;
}

.OrgFooterFixPrimaryButtonDummy{
  height: 56px;
}

.OrgFooterTwitterShareButton{
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 998;
}

.MscFooterPaidFC{
  border-top: 1px solid $color-white-dark;
  position: fixed;
  bottom: 0;
}
