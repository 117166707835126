@import '../../scssUtils/variables';

.OrgFCListBase {
  box-sizing: border-box;
  padding: 16px 0 0;
}

.OrgFCListWrap{
}

.OrgFCListItem{
  border-bottom: solid 1px $color-white-dark;
  margin-bottom: 8px;

  &:last-child{
    border-bottom: none;
  }
}
