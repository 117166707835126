@import '../../scssUtils/variables';

.McsLinkListBase {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
  	opacity: 0.7;
  }
}

.McsLinkListMainTitle{
  font-size: 1.4rem;
}

.McsLinkListSubTitle{
  color: $color-dark-light;
  font-size: 1.2rem;
  margin-top: 8px;
}

.McsLinkListIcon{

}
