@import '../../scssUtils/variables';

.IconBase {
  align-items: center;
  background-color: $color-white;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img{
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.IconPrimary{
}

.IconSecondary{
  border: solid 2px $color-white;
}


.IconSizePrimary{
  height: 48px;
  width: 48px;
}

.IconSizeSecondary{
  height: 40px;
  width: 40px;
}

.IconSizeTertiary{
  height: 36px;
  width: 36px;
}

.IconSizeQuaternary{
  height: 32px;
  width: 32px;
}

.IconSizeQuinary{
  height: 30px;
  width: 30px;
}

.IconSizeSenary{
  height: 16px;
  width: 16px;
}

.IconSizeSeptenary{
  height: 80px;
  width: 80px;
}
