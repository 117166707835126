@import '../../scssUtils/variables';

.LabelBase {
  dt{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-height: 140%;
    margin-top: -2px;
    overflow: hidden;
    word-wrap: break-word;
  }
}

.LabelPrimary{
  dt{
    color: $color-dark;
    font-size: 1.4rem;
    font-weight: bold;
  }

  dd{
    color: $color-gray;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 4px;
  }
}

.LabelSecondary{
  dt{
    color: $color-dark;
    font-size: 1.4rem;
    font-weight: bold;
  }

  dd{
    color: $color-dark-light;
    font-size: 1.2rem;
    margin-top: 4px;
  }
}

.LabelTertiary{
  dt{
    color: $color-dark;
    font-size: 1.2rem;
    font-weight: bold;
  }

  dd{
    color: $color-gray;
    font-size: 1rem;
    margin-top: 2px;
  }
}

.LabelQuaternary{
  dt{
    color: $color-dark;
    font-size: 1.4rem;
  }

  dd{
    color: $color-gray;
    font-size: 1.2rem;
    margin-top: 4px;
  }
}

.LabelQuinary{
  dt{
    color: $color-dark;
    font-size: 1.4rem;
  }

  dd{
    color: $color-dark-light;
    font-size: 1.2rem;
    margin-top: 4px;
  }
}

.LabelSeptenary{
  overflow: hidden;

  dt{
    color: $color-dark-light;
    display: block;
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  dd{
    color: $color-gray;
    font-size: 1.2rem;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
