
.ButtonBase {
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  width: 48px;

  button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.ButtonPlayVideo{
  height:40px;
  width: 40px;

  button{
    background: url('../../assets/images/icon-play-button-white.svg') no-repeat center center;
    background-size: 40px;
  }
}

.ButtonPlaySound{
  height:48px;
  width: 48px;

  button{
    background: url('../../assets/images/icon-play-button.svg') no-repeat center center;
    background-size: 19px;
  }}

.ButtonPauseSound{
  height:48px;
  width: 48px;

  button{
    background: url('../../assets/images/icon-pause.svg') no-repeat center center;
    background-size: 12px 16px;
  }
}


.ButtonSeekSound{
  button{
    background: none;
    height: 48px;
    position: relative;
    width: 48px;
  }

  span{
    background: #fff;
    border: 1px solid #eee;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    display: block;
    height: 16px;
    left: 50%;
    margin: -8px;
    position: absolute;
    top:50%;
    width: 16px;
  }
}
