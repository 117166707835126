@import '../../scssUtils/variables';
@import '../../scssUtils//mixins';

.CheckBoxBase {
  border-radius: 50px;
  display: inline-block;

  input{
    display: none;
  }

  div{
    border-radius: 50px;
    box-sizing: border-box;
    color: $color-white;
    text-align: center;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }

  span {
    font-size: 1.2rem;
    height: 28px;
    white-space: nowrap;
  }
}

.CheckBoxPrimary{
  div{
    padding: 5px 13px;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
    @include gradationColor;
  }

  span{
    color: $color-white;
    font-weight: bold;
  }
}

.CheckBoxSecondary{
  @include gradationColor;

  div{
    background: $color-white;
    height: calc(100% - 2px);
    margin: 1px;
    padding: 4px 12px;
    width: calc(100% - 2px);
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }

  span{
    @include gradationText;

  }
}

.CheckBoxTertiary{
  div{
    padding: 5px 13px;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
    @include gradationColorSecondary;
  }

  span{
    color: $color-white;
    font-weight: bold;
  }
}

.CheckBoxQuaternary{
  @include gradationColorSecondary;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  div{
    background: $color-white;
    height: calc(100% - 2px);
    margin: 1px;
    padding: 4px 12px;
    width: calc(100% - 2px);
  }

  span{
    color: $color-blue;
    // font-weight: bold;

  }
}
