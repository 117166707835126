@import '../../scssUtils/variables';

.McsIconTextBase {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  dd{
    color: $color-dark-light;
    font-size: 1.2rem;

    span{
      color: $color-dark;
      display: inline-block;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }
}
