@import '../../scssUtils/variables';

.OrgLoadingBase {
  align-items: center;
  background: $color-dark;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 3000;
}

.OrgLoadingIcon{
  background: url('../../assets/images/oval.svg') no-repeat
    center center;
  height: 100%;
  position: relative;
  width: 100%;
}
