@import '../../scssUtils/variables';

.OrgPhotosListModalWrap{
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  padding: 16px;
  background-color: rgba($color: $color-dark, $alpha: 0.8);
  z-index: 2888
}

.modalBody{
  height: calc(100% - 42px);
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  overflow:scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modalBody::-webkit-scrollbar {
  display:none;
}

.modalClose {
  width: 100%;
  max-width: 640px;
  display: flex;
  justify-content:flex-end;
  margin: 0 auto 16px;
  div{
    height: 26px;
    width: 26px;
    span {
    margin-left: -8px;
    width: 16px;
    }
  }
}

.photo {
  width: 100%;
  img{
    display: block;
    background-size: cover;
    height: 100%;
    width: 100%;
    max-width: 640px;
  }
  & + & {
    margin: 8px auto 0;
  }
}
