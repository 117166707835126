@import '../../scssUtils/mixins';

.OrgPostContentFanclubListBase {
  background-color: $color-white;
  border: 1px solid $color-gray-light-ddd;
  border-radius: 4px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: inline-block;
}

.OrgPostContentFanclubListTitleWrapper {
  padding: 8px;
  text-align: center;
}

.OrgPostContentFanclubListTitle {
  color: $color-dark-light;
  font-size: 1.2rem;
  font-weight: bold;
}

.OrgPostContentFanclubListList {
}

.OrgPostContentFanclubListListUnit {
  border-top: 1px solid $color-gray-light-ddd;
  padding: 8px;
}
