@import '../../scssUtils/variables';

.OrgDialogModalBase {
  background: $color-white;
  border-radius: 4px;

  /* ds-pannel */
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.OrgDialogModalItem {
  border-top: 1px solid $color-white-dark;
  box-sizing: border-box;
  color: $color-dark-light;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 16px 14px;

  &:first-child {
    border-top: none;
  }
}
