@import '../../scssUtils/variables';

.TitleBase {
}

.TitlePrimary{
  font-size: 1.4rem;
  font-weight: bold;
}

.TitleSecondary{

}
