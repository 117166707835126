@import '../../scssUtils/variables';

.TitleBase {
}

.TitlePrimary{
  dt{
    font-size: 2rem;
    font-weight: bold;
  }

  dd{
    color: $color-gray;
    font-size: 1.2rem;
    margin-top: 8px;
  }
}

.TitleSecondary{

}
