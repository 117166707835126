@import '../../scssUtils/variables';


.OrgFCListUnitBase {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.OrgFCListUnitThumbnail{
  flex-shrink: 0;
  margin-right: 8px;
}

.OrgFCListUnitBlog{
  overflow: hidden;
}

.OrgFCListUnitUser{
  margin-bottom: 4px;
}

.OrgFCListUnitHeading{
  font-size: 1.2rem;
  line-height: 1.5;

  span{
    color: $color-gray;
    display: inline-block;
    margin-left: 1rem;
  }
}
