@import '../../scssUtils/variables';

.McsNavFCLinkLListBase {
  align-items: center;
  // border-bottom: solid 1px $color-gray-light-ccc;
  // border-top: solid 1px $color-gray-light-ccc;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
}

.McsNavFCLinkLListLink {
  border-left: solid 1px $color-gray-light-ccc;
  color: $color-gray;
  height: 28px;
  line-height: 24px;
  margin: 12px 0;
  text-align: center;
  width: 25%;

  &:first-child {
    border: none;
  }
}
