@import '../../scssUtils/mixins';


.container {}

.row {
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &:nth-child(even){
    background-color: $color-white;
  }

}

.summaryTitle{
  @include gradationTextSecondary;

  font-size: 14px;
  font-weight: bold;
}

.summaryPrice{
  font-size: 16px;
  font-weight: bold;
}

.title {
  color: $color-gray;
  font-size: 14px;
  font-weight: bold;
}

.price {
  font-size: 14px;
}
