@import '../common/scssUtils/mixins';

.containerWrap{
  @include containerWraper;
}


.contentTitle{
  padding: 16px;
}

.contentListWrap{
  border-top: solid 2px $color-white-dark;
}

.contentList{
  border-bottom: solid 1px $color-white-dark;

  &:first-child{
    border-top: solid 1px $color-white-dark;
  }
}

.applyWrapper{
  border-top: solid 1px $color-white-dark;
  padding: 16px 8px;
}
