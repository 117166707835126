.McsInputBase {
  display: flex;
}

.McsInputContent {
  width: calc(100% - 96px);
}

.McsInputButton {
  flex-shrink: 0;
  margin-left: 8px;
  width: 88px;
}
