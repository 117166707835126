@import '../common/scssUtils/mixins';

.containerWrap{
  @include containerWraper;
}

.contentTitle{
  border-bottom: 1px solid $color-white-dark;
  padding:16px 0;
}

.read{
  color: $color-dark-light;
  font-size: 1.2rem;
  line-height: 150%;
  white-space: pre-wrap;
  padding: 16px 8px;
}

.textArea{
  margin: 0 8px;
}

.checkbox{
  margin:24px 8px;
}

.buttonWrap{
  margin: 40px 8px 0;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.cancel{
  display: inline-block;
}

.title{
  margin: 16px;
}

.text{
  @extend .read;
  padding: 16px 16px 0;
}
