@import '../../scssUtils//mixins';

.container{
}

.tabMenuWrap{
  border-bottom: solid 1px $color-white-dark;
  display: flex;
}

.tabMenuItem{
  width: 50%;
}

.title{
  font-size: 15px;
  padding-bottom: 16px;
}

.body{
  padding: 16px;
}

.read{
  color: $color-dark-light;
  font-size: 12px;
  line-height: 150%;
  white-space: pre-wrap;
}

.input{
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-top: 24px;
}

.label{
  margin-bottom: 8px;
}

.inputPostal{
  width: 120px;
}

.inputPrice{
  width: 120px;
}

.submit{
  margin-top: 40px;
}

.cancelWrapper{
  margin-top: 8px;
  text-align: center;
  width: 100%;
}

.cancel{
  display: inline-block;
}
