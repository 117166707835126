@import '../../scssUtils/variables';

.McsFooterBannerWrap{
  align-items: center;
  background: $color-white;
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
}
