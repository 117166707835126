@import '../../scssUtils/variables';

$border-color : $color-white;
$border-weight: 4px;
$box-shadow: 0 0 1px 1px rgba($color-gray-light, 1) inset;

.blogPhotoBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.oneColumn {
  width: 100%;
  padding-top: 50%;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: $box-shadow;
}

.twoColumn {
  width: 50%;
  padding-top: 50%;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: $box-shadow;
}

.threeColumn {
  width: calc(100% / 3);
  padding-top: calc(100% / 3);
  align-items: center;
  position: relative;
  box-sizing: border-box;
  box-shadow: $box-shadow;
}

.oneSheet {
  div{
    @extend .oneColumn;
  }
}

.twoSheet {
  div {
    @extend .twoColumn;
    & + div {
      border-left: solid $border-weight $border-color;
    }
  }
}

.threeSheet {
  div:first-of-type {
    @extend .oneColumn;
    border-bottom: solid $border-weight $border-color;
  }
  div:nth-of-type(n+2) {
    @extend .twoColumn;
    & + div {
      border-left: solid $border-weight $border-color;
    }
  }
}

.fourSheets {
  div:first-of-type {
    @extend .oneColumn;
    border-bottom: solid $border-weight $border-color;
  }
  div:nth-of-type(n+2) {
    @extend .threeColumn;
    & + div {
      border-left: solid $border-weight $border-color;
    }
  }
}

.fiveSheets {
  div:first-of-type, div:nth-of-type(2) {
    @extend .twoColumn;
    border-bottom: solid $border-weight $border-color;
  }
  div:nth-of-type(2) {
    border-left: solid $border-weight $border-color;
  }
  div:nth-of-type(n+3) {
    @extend .threeColumn;
    & + div {
      border-left: solid $border-weight $border-color;
    }
  }
}

.fiveSheetsOrMore {
  div:first-of-type, div:nth-of-type(2) {
    @extend .twoColumn;
    border-bottom: solid $border-weight $border-color;
  }
  div:nth-of-type(2) {
    border-left: solid $border-weight $border-color;
  }
  div:nth-of-type(n+3) {
    @extend .threeColumn;
    & + div {
      border-left: solid $border-weight $border-color;
    }
    .orMore{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      opacity: 0.7;
      background-color: $color-dark;
      color: $color-white;
      font-size: 32px;
      line-height: calc(100% / 3);
      text-align: center;
    }
  }
}
