@import '../../scssUtils/variables';

.OrgHeroBase {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
}


.OrgHeroMain{
  position: relative;
}

.OrgHeroImage{
  height: 180px;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 320px;
  }
}

.OrgHeroTitle{
  box-sizing: border-box;
  line-height: 1.3;
  overflow-wrap: break-word;
  padding : 16px;
  word-wrap: break-word;
}


.OrgHeroContent{
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}

.OrgHeroTag{
  box-sizing: border-box;
  padding : 0 16px 16px 16px;
}

.OrgHeroTagItem{
  margin-bottom: 8px;
  margin-right: 8px;
}
