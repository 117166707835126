@import '../../scssUtils/variables';

.OrgCategoryListBase {
}

.OrgCategoryListWrap {
  li {
    margin-bottom: 8px;
  }
}
