@import '../../scssUtils/variables';

.OrgSingUpRegisterBase {
  background-color: $color-bg;
  box-sizing: border-box;
  padding: 32px 16px;
  width: 100%;
}

.OrgSingUpRegisterTitle{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.OrgSingUpRegisterTitleSub{
  color: $color-dark-light;
  font-size: 1.2rem;
  font-weight: bold;
}

.OrgSingUpRegisterInput{
  margin-bottom: 16px;
}


.OrgSingUpRegisterButton{
  margin-bottom: 16px;
}

.OrgSingUpRegisterTwitterButton{
  border-top:solid 1px $color-white-dark;
  padding-top: 16px;
}

.OrgSingUpRegisterNote{
  color: #000;
  display: block;
  font-size: 12px;
  margin-top: 16px;
}
