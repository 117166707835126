@import '../common/scssUtils/mixins';

.detailWrap{
  @include containerWraper;

  padding-bottom: 0;
}

.headButtonWrap{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px 4px;
}

.headButtonItem{
  margin: 0 4px;
  width: 100%;
}

.contentWrap{
  @include contentWrap;
}

.userWrap{
  border-bottom: solid 1px $color-white-dark;
  border-top: solid 1px $color-white-dark;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  @include contentWrap;
}

.blogWrap{
  @include contentWrap;

  overflow-wrap: break-word;
  padding-bottom: 16px;
  padding-top: 16px;
  word-wrap: break-word;
}

.photoWrap{
  // @include contentWrap;

  // padding-bottom: 16px;
  // padding-top: 16px;
}

.movieWrap{
  height: 210px;
  // @include contentWrap;

  // padding-bottom: 16px;
  // padding-top: 16px;
}

.voiceWrap{
  // @include contentWrap;

  // padding-bottom: 16px;
  // padding-top: 16px;
}

.voice{
  height: 210px;
}

.likeFollowWrap{
  @include contentWrap;

  display: flex;
  padding-top: 24px;

  .likeFollowItem{
    margin-right: 16px;
  }
}

.snsWrap{
  @include contentWrap;

  padding-bottom: 24px;
  padding-top: 24px;
}

.commentWrap{
  @include contentWrap;

  border-bottom: solid 1px $color-white-dark;
  padding-bottom: 8px;
  padding-top: 8px;
}

.commentCount{
  @include contentWrap;

  align-items: center;
  border-bottom: solid 1px $color-white-dark;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  p{
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.modalContainer {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.modal{
  height: auto;
  position: relative;
  width: calc(100% - 32px);
  max-width: 765px;
  z-index: 1002;
}

.modalBg{
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1001;
}

.OrgHeroContent{
  @include contentWrap;
  padding-top: 24px;
}
