@import '../../scssUtils/variables';

.OrgSoundPlayerBase {
  align-items: center;
  background: $color-white;
  border: 1px solid $color-white-dark;
  border-radius: 24px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content:flex-start;
  overflow: hidden;
  padding: 0 24px 0 0;
}



.OrgSoundPlayerButton{
  flex-shrink: 0;
}

.OrgSoundPlayerTime{
  color: $color-dark-light;
  flex-shrink: 0;
  font-size: 1.2rem;
  margin-right: 8px + 12px;
}

.OrgSoundPlayerSeek{
  height: 100%;
  position: relative;
  width: 100%;

  &::after{
    background:$color-white-dark;
    content: '';
    height: 2px;
    position: absolute;
    top:22px;
    width: 100%
  }
}

.OrgSoundPlayerSeekButton{
  left: -24px;
  margin-top: -25px;
  position: absolute;
  top:50%;
  z-index: 2;
}

