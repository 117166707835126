@import '../common/scssUtils/mixins';

.artistWrap{
  @include containerWraper;

  padding-bottom: 0;
}

.headButtonWrap{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px 4px;
}

.headButtonItem{
  margin: 0 4px;
  width: 100%;
}

.contentWrap{
  @include contentWrap;

  margin-bottom: 16px;
  margin-top: 8px;
}

.bannerWrap{
  @include contentWrap;

  padding-bottom: 16px;
  padding-top: 16px;
}

.recommendsWrap{
  @include contentWrap;

  border-top: 1px solid $color-white-dark;
  margin-top: 8px;
}
