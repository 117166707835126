@import '../../scssUtils/variables';

.OrgAdminListMenuUnitBase {
  box-sizing: border-box;
  // padding: 8px 0;
}


.OrgAdminListMenuUnitDraftWrapper{
  margin-bottom: 8px;
  position: relative;
}

.OrgAdminListMenuUnitDraft{
  color: #eb5757;
  font-size: 12px;
  font-weight: bold;
}

.OrgAdminListMenuUnitMainContent{
  display: flex;
}

.OrgAdminListMenuUnitUserImage{
  margin-right: 8px;
  position: relative;
}

.OrgAdminListMenuUnitUserText{
  margin-bottom: 8px;
  width: calc(100vw - 88px);
}
.OrgAdminListMenuUnitUserName{}

.OrgAdminListMenuUnitSubContent{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.OrgAdminListMenuUnitTag{
  display: flex;
  flex-wrap: wrap;
}

.OrgAdminListMenuUnitTagItem{
  margin-bottom: 4px;
  margin-right: 4px;
}

.OrgAdminListMenuUnitInfo{
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 150px;
}

.OrgAdminListMenuUnitInfoItem{
  margin-left: 16px;
}

.OrgAdminListMenuUnitVideo{
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  position: absolute;
  top:50%;
}

.OrgAdminListMenuUnitVoice{
  left: 50%;
  margin: auto;
  position: absolute;
  top:50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.OrgAdminListMenuUnitCount{
  left: 0%;
  min-width: 16px;
  padding: 0 1px;
  position: absolute;
  top:0%;
}

.OrgAdminListMenuUnitWall{
  background-color: $color-white-dark;
  height: 64px;
  width: 64px;
}
