@import '../common/scssUtils/mixins';

.container{
  @include containerWraper;

  padding: 16px;
  padding-top: 48px;
}

.title{
  margin-top: 16px;
  text-align: center;
}

.read{
  color: $color-dark-light;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.button{
  margin-top: 24px;

}
