@import '../../scssUtils/variables';

.McsHeaderWrap{
  background: $color-white;
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  height: 57px;
  position: relative;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 48px;
    max-width: 768px;
  }
}

.McsHeaderButton{
  left: 16px;
  position: absolute;
  top:8px;
  width: 68px;
  @media screen and (min-width: 768px) {
    height: 32px;
  }
}

.McsHeaderTitle{
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 57px;
  @media screen and (min-width: 768px) {
    height: 48px;
  }
}
