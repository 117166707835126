@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.SelectBoxBase {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 2px;
  font-size: 1.6rem;
  position: relative;

  select {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    background-image: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 42px;
    line-height: 42px;
    outline: none;
    padding: 0 8px;
    text-indent: 0.01px;
    text-overflow: ellipsis;
    width: 100%;

    option:first-child{
      color: red;
      display: none;
    }
  }
}

.SelectBoxPrimary{
  background: $color-gray-light;
  position: relative;

  span{
    &::before{
      border-right: solid 2px $color-dark-light;
      border-top: solid 2px  $color-dark-light;
      content: '';
      height: 6px;
      margin-top: -6px;
      position: absolute;
      right: 8px;
      top:50%;
      transform: rotate(135deg);
      width: 6px;
    }
  }
}


.SelectBoxSecondary{
  background: $color-gray-light;
  border: none;
  position: relative;

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColor;
  }

  span{
    &::after{
      content: '';
      height: 8px;
      margin-top: -6px;
      position: absolute;
      right: 8px;
      top:50%;
      transform: rotate(135deg);
      width: 8px;

      @include gradationColor;
    }

    &::before{
      background: $color-gray-light;
      content: '';
      height: 8px;
      margin-top: -9px;
      position: absolute;
      right: 8px;
      top:50%;
      transform: rotate(135deg);
      width: 8px;
      z-index: 2;
    }
  }


}

.SelectBoxTertiary{
  background: $color-gray-light;
  border: none;
  position: relative;

  &::after{
    background: $color-dark-light;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  span{
    &::before{
      border-right: solid 2px $color-dark-light;
      border-top: solid 2px  $color-dark-light;
      content: '';
      height: 6px;
      margin-top: -6px;
      position: absolute;
      right: 8px;
      top:50%;
      transform: rotate(135deg);
      width: 6px;
    }
  }
}

.SelectBoxDisabled{
  &::after{
    background: $color-gray-light-ccc;
  }

  &::before{
    border-right: solid 2px  $color-gray-light-ccc;
    border-top: solid 2px  $color-gray-light-ccc;
  }
}


.SelectBoxError{
  background: $color-red-light;
  border: solid 1px $color-red;
  color: $color-red;

}

.SelectBoxErrorWithOutBorder{
  border: none;
}

.SelectBoxErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
