@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.InputBase {
  overflow: hidden;

  input[type='file']{
    background-color: transparent;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .InputFileThumbnail{
    align-items: center;
    background: $color-gray-light;
    display: flex;
    height: 128px;
    justify-content: center;
    position: relative;
    width: 100%;
    @media screen and (min-width: 768px) {
      height: 192px;
    }
  }
}

.InputFileThumbnailCover{
  background: $color-dark;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.InputFileVideoPreview{
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
}

.InputFileVoicePreview{
  background: linear-gradient(107.18deg, #1156db 0%, #11abdb 99.64%);
  height: 100%;
  position: absolute;
  width: 100%;
}

.InputFileTitle{
  color: $color-white;
  font-size: 1.4rem;
  font-weight: bold;
  height: 27px;
  line-height: 27px;
  position: relative;
}

.InputFileTitleNull{
  color: $color-gray;
  font-size: 1.4rem;
  font-weight: bold;
  height: 27px;
  line-height: 27px;
  position: relative;
}

.InputFileName{
  bottom: 24px;
  color: $color-white;
  position: absolute;
}

.InputPrimary{
  .InputFileTitle{
    background: url('../../assets/images/icon-camera.svg') no-repeat center left;
    background-size: 27px 24px;
    padding-left: 32px;
  }

  .InputFileTitleNull{
    background: url('../../assets/images/icon-camera-gray.svg') no-repeat center left;
    background-size: 27px 24px;
    padding-left: 32px;
  }
}

.InputSecondary{
  .InputFileTitle{
    background: url('../../assets/images/icon-video.svg') no-repeat center left;
    background-size: 30px 20px;
    padding-left: 35px;
  }

  .InputFileTitleNull{
    background: url('../../assets/images/icon-video-gray.svg') no-repeat center left;
    background-size: 30px 20px;
    padding-left: 35px;
  }
}

.InputTertiary
{
  .InputFileTitle{
    background: url('../../assets/images/icon-voice.svg') no-repeat center left;
    background-size: 30px 20px;
    padding-left: 35px;
  }

  .InputFileTitleNull{
    background: url('../../assets/images/icon-voice-gray.svg') no-repeat center left;
    background-size: 30px 20px;
    padding-left: 35px;
  }
}

.InputQuaternary
{
  .InputFileTitle{
    background: url('../../assets/images/icon-id.svg') no-repeat center left;
    background-size: 27px 19px;
    padding-left: 35px;
  }

  .InputFileTitleNull{
    background: url('../../assets/images/icon-id-gray.svg') no-repeat center left;
    background-size: 27px 19px;
    padding-left: 35px;
  }
}



.InputError{
}

.InputErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
