
.ButtonBase {
  font-weight: bold;
  height: 48px;
  width: 48px;

  button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.ButtonPrimary{
  button{
    background: url('../../assets/images/icon-search.svg') no-repeat center center;
    background-size: 22px 24px;
  }
}
