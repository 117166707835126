@import '../../scssUtils/variables';

.OrgFCListBase {
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  padding-top: 16px;
}

.OrgFCListTitle {
  margin-left: 16px;
}

.OrgFCListWrap {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 16px 0;
  width: 100%;

  li {
    margin-right: 8px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }
}
