@import '../../scssUtils/variables';

.CheckBoxBase {
  input{
    display: none;
  }
}

.CheckBoxPrimary{
  span{
    color: $color-dark-light;
    font-size: 1.4rem;
    line-height: 1.4rem;
    padding-left: 24px;
    position:relative;

    &::before{
      background: $color-white;
      border: 1px solid $color-gray-light-ccc;
      border-radius: 4px;
      content: '';
      display: block;
      height: 16px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 16px;
    }
  }

  input:checked + span{
    color: $color-dark-light;
  }

  input:checked + span::after{
    border-bottom: 2px solid $color-dark-light;
    border-right: 2px solid$color-dark-light;
    content: '';
    display: block;
    height: 10px;
    left: 6px;
    position: absolute;
    top: 3px;
    transform: rotate(40deg);
    width: 5px;
  }
}
