@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.OrgFCListBase{
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.OrgFCListWrap{
  @include contentWrap;
  &:first-of-type{
    margin-top: 10px;
  }
}

.OrgFCListTabMenuWrap{
  border-bottom: solid 1px $color-white-dark;
  width: 100%;
}

.OrgFCListTabMenu{
  box-sizing: border-box;
  display: flex;
  padding: 0 16px;
  width: 100%;
}

.OrgFCListTabMenuItem{
  width: 17.5%;

  &:nth-child(1){
    width: 30%;
  }}


.OrgFCListPhotoWrap{
  display: flex;
  flex-wrap: wrap;
}

.OrgFCListPhotoItem{
  border-bottom: solid 1px $color-white;
  box-sizing: border-box;
  height: calc((100vw - 2px) / 3);
  margin-bottom: 1px;
  width: calc((100vw - 2px) / 3);

  &:nth-child(3n+2){
    margin-left: 1px;
    margin-right: 1px;
  }
}

.hr{
  background: $color-white-dark;
  border: none;
  display: block;
}
