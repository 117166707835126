@import '../../scssUtils/variables';

.OrgFCListUnitBase {
  box-sizing: border-box;
  padding: 8px 0;
}

.OrgFCListUnitUser{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: default;
  &:hover{
    opacity: 1;
  }
}

.OrgFCListUnitTime{
  color: $color-gray;
  font-size: 1.2rem;
}

.OrgFCListUnitComment{
  color: $color-dark-light;
  font-size: 1.2rem;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.OrgFCListUnitDelete{
  margin-top: 8px;
  width: 144px;
}
