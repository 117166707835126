@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.InputBase {
  input[type='file']{
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  .InputFileThumbnail{
    align-items: center;
    background: $color-gray-light;
    border: 1px solid $color-white-dark;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }
}


.InputFileTitle{
  color: $color-gray;
  font-size: 1.4rem;
  font-weight: bold;
  height: 27px;
  line-height: 27px;
  position: relative;
}


.InputPrimary{
  .InputFileTitle{
    background: url('../../assets/images/icon-camera-gray.svg') no-repeat top center;
    background-size: 27px 24px;
    padding-top: 28px;
  }
}
