@import '../../scssUtils/variables';

.McsFooterBannerWrap{
  background: $color-white;
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  height: 56px;
  position: relative;
  text-align: center;
  width: 100%;
}

.McsFooterBannerButton{
  left: 16px;
  position: absolute;
  top:8px;
  width: 68px;
}

