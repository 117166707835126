@import '../common/scssUtils/mixins';

.containerWrap{
  @include containerWraper;
}

.title{
  box-sizing: border-box;
  padding: 32px 16px 16px;
}

.list{
  border-top: solid 2px $color-white-dark;
}

.listItem{
  border-top: solid 1px $color-white-dark;
  box-sizing: border-box;
  height: 49px;
  padding-left: 16px;
  padding-right: 4px;
}

.listItemCredit{
  border-top: solid 1px $color-white-dark;
  box-sizing: border-box;
  height: 49px;
  padding-left: 16px;
  padding-right: 4px;
  white-space: pre-wrap;
}

.listItemLast{
  border-bottom: solid 1px $color-white-dark;
  border-top: solid 1px $color-white-dark;
  box-sizing: border-box;
  height: 49px;
  padding-left: 16px;
  padding-right: 4px;
}
