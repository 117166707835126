@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.base {
}

.cardList{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card{
  display: flex;
  justify-content: center;
  margin: 0 8px;
  width: 48px;

  img{
    width: 100%;


  }
}

.read{
  color: $color-dark-light;
  font-size: 12px;
  line-height: 150%;
  margin-top: 8px;
  text-align: center;
  white-space: pre-wrap;
}
