@import '../common/scssUtils/mixins';

.commentWrap{
  @include containerWraper;

  padding-top: 57px;
}

.commentHeader{
  position: fixed;
  top:0;
  z-index: 1000;
}


.contentWrap{
  @include contentWrap;

}


.commentItem{
  @include contentWrap;

  border-bottom: solid 1px $color-white-dark;
}

.followModalWrap{
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 1001;
}

.followModal{
  height: auto;
  position: relative;
  width: calc(100% - 32px);
  z-index: 1002;
}

.followModalBg{
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1001;
}

.commentInput{
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
}
