@import '../../scssUtils/mixins';

.container {
  text-align: center;
}

.price{
  font-family: 'Roboto Condensed', $sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin-top: 16px;

  span {
    font-family: $sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
}
