@import '../../scssUtils/mixins';

.OrgPostContentBase {
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
  padding: 4px;
  position: relative;
  width: 100%;
}

.OrgPostContentButton {
  @include gradationColorSecondary;

  align-items: center;
  border: none;
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 100%;

  p{
    color: $color-white;
    font-size: 1.6rem;
    margin-left: 8px;
  }
}

.OrgPostContentModal {
  bottom: 64px;
  position: absolute;
  right: 8px;
  width: 200px;
  z-index: 4001;
}

.OrgPostContentModalBg {
  background-color: rgba(0,0,0,0);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 48px;
  z-index: 4000;
}
