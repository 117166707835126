@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.InputBase {
  input[type='file']{
    background-color: green;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
  }

  label {
    background: $color-red;
    border: solid 2px $color-white;
    border-radius: 50%;
    display: block;
    height: 75px;
    margin: -18px 0 0 18px;
    overflow: hidden;
    position: relative;
    width: 75px;

    span{
      background: url('../../assets/images/icon-camera.svg') no-repeat center center;
      background-size: 27px 24px;
      display: block;
      height: 75px;
      width: 75px;
    }
  }
}

.InputFileThumbnail{
  align-items: center;
  background-color: $color-red;
  display: flex;
  height: 128px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 192px;
  }
  input[type='file']{
    display: flex;
    height: 128px;
  }

  span {
    background: url('../../assets/images/icon-camera.svg') no-repeat center center;
    background-size: 27px 24px;
    border-radius: 50%;
    display: block;
    height: 75px;
    position: relative;
    width: 75px;
  }
}

.InputPrimary{
}

.InputError{
}

.InputErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
