@import '../common/scssUtils/mixins';

.headerWrap{
  height: 48px;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 997;
  @media screen and (min-width: 768px) {
    padding: 0 calc(50vw - 384px);
  }
}

.headerNavWrap{
  height: 100%;
  position: fixed;
  top:48px;
  width: 100%;
  z-index: 999
}

.headerNavContent{
  height: calc(100% - 80px);
  left: 16px;
  overflow: auto;
  position: fixed;
  top:48px + 16px;
  width: calc(100% - 32px);
  z-index: 999;
}

.headerNavContentMain{
  margin-bottom: 16px;
}

.headerNavBg{
  background: rgba($color: #000, $alpha: 0.8);
  height: 100%;
  position: fixed;
  top:48px;
  width: 100%;
  z-index: 998;
}

.headerSearchContainer{
  background-color: $color-white;
  height: calc(100% - 48px);
  overflow-y: scroll;
  position: fixed;
  top:48px;
  width: 100%;
  z-index: 999;
}

.headerSearchTitle{
  font-size: 20px;
  font-weight: bold;
  max-width: 768px;
  margin: 32px auto 24px;
  padding: 0 16px;
}
