.McsTextAreaBase {
}

.McsTextAreaLabel{
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;

  div{
    margin-right: 8px;
  }
}

.McsTextAreaMaxLength{
  font-size: 1.2rem;
  margin-top: 8px;
  text-align: right;
}
