@import '../common/scssUtils/mixins';

.containerWrap{
  @include container;

  padding: 72px 0 24px;
}

.contentTitle{
  padding: 16px 0;
}

.title{
  font-size: 15px;
  margin-bottom: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;


  p{
    color: $color-dark-light;
    margin-bottom: 24px;
  }

  span{
    color: $color-dark;
    font-weight: bold;
  }
}

.contentWrap{
  margin: 0 8px;
}

.buttonWrap{
  margin-top: 32px;
}

.button{
  margin-bottom: 16px;
}

.adminBarContainer{
  border-top: solid 1px #eee;
  margin-top: 16px;
}

.adminNavContainer{
  // border-top: solid 1px #eee;
  background-color: #f6f6f6;
}

.followerListContainer{

}

.followerListItem{
  border-bottom: 1px solid $color-white-dark;
}

.refineSearchButtonWrapper{
  border-top: 1px solid $color-white-dark;
  padding: 16px 8px;
}
