@import '../../scssUtils/variables';

.McsTitleAndLabelButtonListUnitBase {
  align-items: center;
  background: $color-white-dark;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  justify-content: space-between;
  padding: 0 8px;
}


.McsTitleAndLabelButtonListUnitTitle{
  font-size: 15px;
}
