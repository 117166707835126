@import '../common/scssUtils/mixins';

.containerWrap{
  @include container;

  padding-left: 0;
  padding-right: 0;
}

.wrapper{
  padding: 0 16px;
}

.title{
  margin-bottom: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 24px;

  p{
    color: $color-dark-light;
  }
}

.inputBox{
  margin-bottom: 24px;
}

.buttonWrap{
  margin-top: 32px;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.cancel{
  display: inline-block;
}
