@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.IconBase {
  position: relative;
}

.IconPrimary {
  height: 20px;
  width: 20px;

  span {
    background: $color-dark;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 10px;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}

.IconSecondary {
  background: $color-white;
  border-radius: 50%;
  height: 20px;
  width: 20px;

  span {
    background: $color-gray;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 10px;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}

.IconTertiary {
  height: 24px;
  width: 24px;

  span {
    @include gradationColorSecondary;

    display: block;
    height: 2px;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 24px;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}

.IconQuaternary {
  height: 14px;
  width: 14px;

  span {
    background: $color-blue;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -5px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 14px;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}

.IconQuinary {
  height: 20px;
  width: 20px;

  span {
    background: $color-white;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 12px;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}
