@import '../../scssUtils/variables';

.ButtonBase {
  text-align: center;

  a{
    display: block;
    height: 100%;
    width: 100%;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}

.ButtonPrimary{
  background:$color-white-dark;

  a{
    color: $color-dark-light;
    text-decoration: none;
  }
}

.ButtonSizePrimary{
  font-size: 1.2rem;
  height: 32px;
  line-height: 32px;
}
