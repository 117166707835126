@import '../../scssUtils/variables';

.LabelBase {
}

.LabelPrimary{
  color: $color-dark;
  font-size: 1.4rem;
  font-weight: bold;

  span{
    color: $color-dark-light;
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 1rem;
  }
}

.LabelSecondary{
  color: $color-dark-light;
  font-size: 1.2rem;

  span{
    color: $color-gray;
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }
}

.LabelTertiary{
  color: $color-dark-light;
  font-size: 1rem;

  span{
    color: $color-gray;
    display: inline-block;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
