@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.messageBase{
  font-size: 12px;
  padding: 8px;
  background-color: $color-white-dark;
  color: $color-dark-light;
  text-align: center;
  border-radius: 48px;
  width: 50%;
  margin: 0 auto;
}




