@import '../../scssUtils/variables';
@import '../../scssUtils//mixins';

.IconBase {
  border-radius: 4px 0 4px 0;
  color: $color-white;
  font-size: 1.2rem;
  height: 16px;
  line-height: 16px;
  position: relative;
  text-align: center;

  span{
    display: inline-block;
    transform: scale(0.8, 0.8);
  }
}

.Primary{
  @include gradationColor;
}

.Secondary{
  @include gradationColorSecondary;
}

.Tertiary{
  background-color: #ffd314;
}

.Quaternary{
  background-color: #c7c2b0;
}

.Quinary{
  background-color: #cb8c42;
}

.Senary{
  background-color: #96a9bc;
}
