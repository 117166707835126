@import '../../scssUtils/variables';

.OrgFCListUnitBase {
  box-sizing: border-box;
  // padding: 8px 0;
  overflow: hidden;
}

.PaidFCPhotos{
  filter: blur(10px);
  margin: -10px;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
}
