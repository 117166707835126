@import '../../../common/scssUtils/mixins';

.OrgUserListModalNavWrap{
  height: 100%;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 2888
}

.OrgUserListModalNavContent{
  height: calc(100% - 80px);
  left: 50%;
  transform: translateX(-50%);
  overflow: auto;
  position: fixed;
  top:48px + 16px;
  width: calc(100% - 32px);
  max-width: 768px;
  z-index: 2888;
}

.OrgUserListModalNavBg{
  background: rgba($color: #000, $alpha: 0.8);
  height: 100%;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 2887;
}
