@import '../../scssUtils/variables';

.McsNavBlogPrevNextBase {
  align-items: center;
  border-bottom:solid 1px $color-gray-light-ccc;
  border-top: solid 1px $color-gray-light-ccc;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
}

.McsNavBlogPrevNextLink{
  color: $color-gray;
  font-size: 1.4rem;
  height: 24px;
  line-height: 24px;
  margin: 12px 0;
  text-align: center;
  cursor: pointer;
  &:nth-child(1){
    width: 37%;
  }

  &:nth-child(2){
    border-left:solid 1px $color-gray-light-ccc;
    border-right: solid 1px $color-gray-light-ccc;
    min-width: 90px;
    width: 26%;
  }

  &:nth-child(3){
    width: 37%;
  }
}
