@import '../../scssUtils/variables';

.McsSelectBase {
}

.McsSelectLabel{
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;

  div{
    margin-right: 8px;
  }
}


.McsSelectSubLabel{
  margin-bottom: 6px;

  div{
    color: $color-gray;
    font-size: 1.2rem;
  }
}

.McsSelectMaxLength{
  font-size: 1.2rem;
  margin-top: 8px;
  text-align: right;
}


.McsSelectFlex{
  display: flex;
  width: 100%;
}

.McsSelectFlexItem{
  margin-left: 8px;
  width: calc(100% / 3);

  &:first-child{
    margin-left: 0;
  }
}

.McsSelectFlexItemHalf{
  margin-left: 8px;
  width: calc(100% / 2);

  &:first-child{
    margin-left: 0;
  }
}

.McsSelectBoxErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
