@import '../../scssUtils/variables';

.IconBase {
  //大きさはどこに持たせる？？？？？
  height: 40px;
  width: 40px;

  span{
    display: block;
    height: 100%;
    position: relative;
    width: 100%;

    &::after{
      border-right: solid 2px $color-gray-light-ddd;
      border-top: solid 2px $color-gray-light-ddd;
      content: '';
      height: 10px;
      left: 50%;
      margin: -6px 0 0 -8px;
      position: absolute;
      top:50%;
      transform: rotate(45deg);
      width: 10px;
    }
  }
}

.IconPrimary{
  span{
    &::after{
      border-right: solid 3px $color-gray-light-ddd;
      border-top: solid 3px $color-gray-light-ddd;
    }
  }
}

.IconSecondary{
  span{
    &::after{
      border-right: solid 2px $color-dark-light;
      border-top: solid 2px  $color-dark-light;
      height: 8px;
      width: 8px;
    }
  }
}

.IconTertiary{
  span{
    &::after{
      border-right: solid 2px $color-dark-light;
      border-top: solid 2px  $color-dark-light;
      height: 6px;
      margin: -4px 0 0 -4px;
      width: 6px;
    }
  }
}

.IconQuaternary{
  span{
    &::after{
      border-right: solid 2px $color-red;
      border-top: solid 2px  $color-red;
      height: 8px;
      margin: -4px 0 0 -4px;
      transform: rotate(-135deg);
      width: 8px;
    }

    &::before{
      background: $color-red;
      content: '';
      height: 2px;
      left: 50%;
      margin-left: -4px;
      margin-top: 0;
      position: absolute;
      top:50%;
      width: 12px;
    }
  }
}

.IconQuinary{
  span{
    &::after{
      border-right: solid 2px $color-blue;
      border-top: solid 2px  $color-blue;
      height: 8px;
      margin: -4px 0 0 -4px;
      transform: rotate(-135deg);
      width: 8px;
    }

    &::before{
      background: $color-blue;
      content: '';
      height: 2px;
      left: 50%;
      margin-left: -4px;
      margin-top: 0;
      position: absolute;
      top:50%;
      width: 12px;
    }
  }
}
