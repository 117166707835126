.OrgAdminListMenuUnitBase {
  box-sizing: border-box;
  // padding: 8px 0;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}

.OrgAdminListMenuUnitMenu{
  align-items: center;
  display: flex;
  height: 49px;
  justify-content:flex-start;
  position: relative;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}

.OrgAdminListMenuUnitIcon{
  height: 48px;
  width: 48px;
}

.OrgAdminListMenuUnitIconWithBadge{
  position: relative;
  div:first-child{
    height: 48px;
    width: 48px;
  }
  div:nth-child(2){
    position: absolute;
    top: 5px;
    right: 6px;
  }
}

.OrgAdminListMenuUnitArrow{
  margin-top:-20px;
  position: absolute;
  right: 0;
  top:50%;
}


