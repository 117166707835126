@import '../../scssUtils/variables';

.OrgContentBlogBase {align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.OrgContentBlogBodyWrapper{
  box-sizing: border-box;
  width: 100%;
}

.OrgContentBlogBody{
  font-size: 14px;
  line-height: 150%;
  white-space: pre-wrap;
  iframe{
    max-width: 100%;
  }
}

.OrgContentBlogThumbnail{
  display: block;
  background-size: cover;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  max-width: 640px;
  margin: 32px auto 0;
}

.OrgContentBlogPhotos{
  margin-top: 32px;
}
