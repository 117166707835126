@import '../../scssUtils/variables';

.IconBase {
  box-sizing: border-box;
  overflow: hidden;

  img{
    height: auto;
    width: 100%;
  }
}


.IconCloseButton{
  left: 4px;
  position: absolute;
  top:4px;
}

.IconPrimary{
}

.IconSecondary{
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}



.IconSizePrimary{
}

