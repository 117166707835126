@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.TabBase {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.TabPrimary{
  // background: $color-white;

  span{
    @include gradationText;
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColor;
  }
}

.TabSecondary{
  // background: $color-white;

  span{
    color: $color-gray;
  }

  &::after{

    // @include gradationColor;
    border-bottom: solid 1px $color-gray-light-ccc;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.TabTertiary{
  span{
    color: $color-dark;
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColor;
  }
}

.TabQuaternary{
  span{
    color: $color-gray;
  }
}

.TabQuinary{
  span{
    @include gradationTextSecondary;
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColorSecondary;
  }
}

.TabSenary{
  span{
    color: $color-dark;
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColorSecondary;
  }
}

.TabSizePrimary{
  height: 48px;
  line-height: 48px;

  span{
    font-size: 1.5rem;
    font-weight: bold;
  }
}
