@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.MscChatWrap{
  display: flex;
}

.iconSpace{
  width: 36px;
}

.contentSpace{
  width: calc(100% - 36px);
}


.chatContent{
  margin-top: 4px;
}

.time{
  font-size: 1.2rem;
  color: $color-gray;
  margin-top: 4px;
}

.OthersContentWithIcon{
  @extend .contentSpace;
  text-align: left;
  div:nth-child(2){
    display: flex;
    align-items: flex-end;
  }
}

.OthersContent{
  div:first-child{
    display: flex;
    align-items: flex-end;
  }
  text-align: left;
}


.MyselfContent{
  div:first-child{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
  text-align: right;
}

.DeleteIcon{
  margin: 0 4px;
  height: 11px;
  width: 12px;
  span {
    background-size: 11px 12px;
  }
}
