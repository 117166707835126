@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.LabelBase {
  display: inline-block;
  height: 18px;

  span {
    box-sizing: border-box;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    height: 100%;
    line-height: 18px;
    padding: 0 8px;
    white-space: nowrap;
    width: 100%;
  }
}

.LabelPrimary {
  span {
    background: $color-red;
    color: $color-white;
  }
}

.LabelSecondary {
  span {
    background: $color-gray;
    color: $color-white;
  }
}

.LabelTertiary {
  height: 22px;

  span {
    border: solid 1px $color-red;
    border-radius: 4px;
    color: $color-red;
    font-size: 1.2rem;
    line-height: 20px;
  }
}

.LabelQuaternary {
  @include gradationColorSecondary;

  border-radius: 50px;
  height: 28px;

  span {
    color: $color-white;
    font-size: 1.2rem;
    line-height: 28px;
    margin: 0 1px;
  }
}

.LabelQuinary {
  border-radius: 50px;
  height: 28px;

  @include gradationColorSecondary;

  span {
    background: $color-white;
    border-radius: 50px;
    color: $color-blue;
    font-size: 1.2rem;
    height: 28px;
    height: calc(100% - 2px);
    line-height: 26px;
    margin: 1px;
    width: calc(100% - 2px);
  }
}

.LabelSenary {
  height: 20px;

  span {
    border: solid 1px $color-gray;
    border-radius: 20px;
    color: $color-gray;
    font-size: 1rem;
    line-height: 18px;
  }
}

.LabelSeptenary {
  background: $color-blue;
  border-radius: 50px;
  color: $color-white;
  height: 18px;

  span {
    font-size: 1rem;
    font-weight: bold;
    line-height: 18px;
  }
}

.LabelPickUp {
  height: 16px;
  width: 52px;

  span {
    background: url('../../assets/images/icon-pickup.svg') no-repeat center
      center;
    background-size: 52px 16px;
    text-indent: -9999px;
  }
}
