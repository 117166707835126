@import '../../../common/scssUtils/mixins';

.container{
  align-items: center;
  background: rgba($color: #000, $alpha: 0.8);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 16px;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 2888;
}

.frame{
  background-color: $color-white;
  border-radius: 4px;
  display: inline-box;
  position: relative;
  width: 100%;
  max-width: 765px;
}

.titleContainer{
  border-bottom: 1px solid $color-white-dark;
}

.title{
  font-size: 14px;
  font-weight: bold;
  margin: 16px 0;
  text-align: center;
}

.iconClose{
  position: absolute;
  right: 18px;
  top: 12px;
}

.contentContainer{
  border-bottom: 1px solid $color-white-dark;
  padding: 16px 8px;
}

.contentTitle{
  color: $color-gray;
  font-size: 12px;
  font-weight: bold;
}

.contentBodySearchWord{
  margin-top: 8px;
}

.contentBodyAttributes{
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.contentBodyAttributesButton{
  width: calc((100% - 8px) / 2);
}

.submitWrapper{
  padding: 8px;
}
