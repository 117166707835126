@import '../common/scssUtils/mixins';

.containerWrap{
  @include container;
}

.title{
  margin-bottom: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 24px;
  white-space: pre-wrap;

  p{
    color: $color-dark-light;
  }

  span{
    color: $color-dark;
    font-weight: bold;
  }
}

.textArea{
  margin-bottom: 24px;
}

.buttonWrap{
  margin-top: 32px;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.cancel{
  display: inline-block;
}
