
.IconBase {
  height: 24px;
  width: 92px;

  a{
    display: block;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.IconPrimary{
  background: url('../../assets/images/krap-logo.svg') no-repeat center center;
  background-size: 92px 24px;
}

