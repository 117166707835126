@import '../../scssUtils/variables';

.McsSNSButtonsBase {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;

  li{
    height: 100%;
    text-align: center;
    width: calc(100% / 3);
  }
}

.McsSNSButtonsTwitter{
  background: $color-twitter;
}

.McsSNSButtonsFaceBook{
  background: $color-facebook;
}

.McsSNSButtonsLine{
  background: $color-line;
}
