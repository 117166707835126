@import '../../scssUtils/variables';

.OrgHeaderBarBase {align-items: center;
  background: $color-white;
  border-bottom: solid 1px $color-gray-light;
  box-sizing: border-box;
  display: flex;
  height: 49px;
  justify-content: space-between;
  width: 100%;
}

.OrgHeaderBarUserIcon{
  box-sizing: border-box;
  height: 48px;
  padding: 9px;
  width: 48px;
  cursor: pointer;
}

.OrgHeaderBarUserIconDummy{
  box-sizing: border-box;
  height: 48px;
  opacity: 0;
  padding: 9px;
  width: 48px;
}

.OrgHeaderBarLeftContainer{
  display: flex;
}

.OrgHeaderBarRightContainer{
  display: flex;
}

.OrgHeaderBarBell{
  position: relative;
  cursor: pointer;
}

.OrgHeaderBarBellBadge{
  position: absolute;
  right: 6px;
  top: 6px;
}
