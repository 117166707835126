.McsButtonWithCheckBase {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  div{
    width: 50%;

    &:first-child{
      margin-right: 24px;
    }
  }
}
