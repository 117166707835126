.OrgFCListUnitBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.OrgFCListUnitUser{
  flex-basis: auto;
  margin-right: 8px;
}

.OrgFCListUnitUserNoFollow{
  flex-basis: auto;
}

.OrgFCListUnitFollowButton{
  flex-basis: 84px;
  min-width: 84px;
}
