@import '../../scssUtils/variables';

.McsFooterBannerWrap{
  background: $color-white;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  z-index: 100;
}

.IconParts{
  display: inline-block;
  line-height: 48px;
  height: 48px;
  width: 100%;
  cursor: pointer;
  &Active{
    @extend .IconParts;
    cursor: default;
  }
}

.timelineIconWrapper{
  display: inline-block;
  width: 50%;
}

.chatIconWrapper{
  display: inline-block;
  width: 50%;
  position: relative;
}

.chatBadge{
  position: absolute;
  right: calc(50% - 20px);
  top: 6px;
}


