@import '../../scssUtils/variables';

.OrgSingUpLoginBase {
  box-sizing: border-box;
  width: 100%;
}

.OrgSingUpLoginTitle{
  margin: 16px 0;
}

.OrgSingUpLoginInput{
  margin-bottom: 8px;
}

.OrgSingUpLoginLink{
  display: block;
  margin: 16px 0 16px 0;
}

.OrgSingUpLoginButton{
  margin-bottom: 16px;
}

.OrgSingUpLoginTwitterButton{
  border-top:solid 1px $color-white-dark;
  padding-top: 16px;
}
