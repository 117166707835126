@import '../../scssUtils/variables';

.IconBase {
  align-items: center;
  background-color: $color-white;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;

  img{
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.IconPrimary{
}

.IconSecondary{
  border: solid 2px $color-white;
}



.IconSizePrimary{
  border-radius: 24px;
  height: 80px;
  width: 80px;
}

.IconSizeSecondary{
  border-radius: 20px;
  height: 72px;
  width: 72px;
}

.IconSizeTertiary{
  border-radius: 16px;
  height: 64px;
  width: 64px;
}

.IconSizeQuaternary{
  border-radius: 12px;
  height: 40px;
  width: 40px;
}

.IconSizeQuinary{
  border-radius: 11px;
  height: 36px;
  width: 36px;
}

.IconSizeSenary{
  border-radius: 8px;
  height: 32px;
  width: 32px;
}

.IconSizeSeptenary{
  border-radius: 6px;
  height: 24px;
  width: 24px;
}
