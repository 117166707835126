@import '../../scssUtils/variables';

.OrgContentVoiceBase{
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}


.OrgContentVoiceThumbnail       {
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  height: 100%;
  min-height: 210px;
  width: 100%;
}

.OrgContentVoicePlayerWrapper   {
  bottom: 18px;
  left: 16px;
  position: absolute;
  right: 16px;
}
