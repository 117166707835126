@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.NewsContainer{
  margin-top: 48px;
}

.NewsHeader{
  align-items: center;
  border-bottom: 1px solid $color-white-dark;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
}

.NewsNoticeText{
  font-size: 15px;
  font-weight: bold;
}

.NewsMarkAsRead{
  color: $color-gray;
  font-size: 12px;
  font-weight: bold;
}

.NewsContentsContainer{
}

.NewsContentContainer{
  border-bottom: 1px solid $color-white-dark;
  display: flex;
  padding: 8px;
}

.NewsContentContainerUnread{
  background-color: #fffcf0;
  border-bottom: 1px solid $color-white-dark;
  display: flex;
  padding: 8px;
}

.NewsContentMiddleContainer{
  margin-left: 8px;
  width: calc(100vw - 80px);
}

.NewsContentMainText{
  color: $color-dark-light;
  font-size: 14px;
  line-height: 130%;
}

.NewsContentDateTime{
  color: $color-gray;
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
}

.NewsContentMarkWrapper{
  align-items: center;
  display: flex;
  margin-left: 8px;
}

.NewsContentMark{
  background-color: $color-red;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.NewsContentKRAPIcon{
  border: 1px solid $color-white-dark;
}
