.ButtonBase {
  a{
    display: block;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.ButtonTwitter{
  background: url('../../assets/images/icon-twitter-white.svg') no-repeat center center;
  background-size: 22px 18px;
}

.ButtonFaceBook{
  background: url('../../assets/images/icon-facebook-white.svg') no-repeat center center;
  background-size: 12px 23px;
}

.ButtonLine{
  background: url('../../assets/images/icon-line-white.svg') no-repeat center center;
  background-size: 26px 25px;
}

.ButtonSizePrimary{
  height: 100%;
  min-height: 26px;
  min-width: 26px;
  width: 100%;
}
