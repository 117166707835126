@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.StripeElement {
  background-color: $color-gray-light;
  border-bottom: 3px solid $color-dark-light;
  box-sizing: border-box;
  height: 42px;
  padding: 12px;

  &#cvc{
    width: 110px;
  }

  &#expiry{
    width: 110px;
  }
}

.StripeElement--focus {
  border-bottom: 3px solid $color-red;
}

.StripeElement--invalid {
  background: $color-red-light;
  border-bottom: solid 3px $color-red;
  color: $color-red;

  &::placeholder{
    color: $color-red;
  }
}

.CardNumberField {
  height: 32px;
}
