@import '../../scssUtils/variables';

.OrgJoinFCModalBase {
  background: $color-white;
  border: 1px solid $color-white-dark;
  border-radius: 16px;

  /* ds-pannel */

  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  height: 100%;
  padding: 24px 0;
  position: relative;
  // width: 100%;
}

.OrgJoinFCModalTitle{
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 16px;
  text-align: center;
}

.OrgJoinFCModalMain{
  position: relative;
}

.OrgJoinFCModalImage{
  height: 128px;
  width: 100%;
}

.OrgJoinFCModalThumbnail{
  margin-left: 16px;
  margin-top: -16px;
}

.OrgJoinFCModalContent{
  box-sizing: border-box;
  margin-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
}
