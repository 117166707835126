@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.containerWrap{
  @include container;

  padding-top: 48px;

}

.summary{
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;

}

.description{
  color: $color-dark-light;
  font-size: 14px;
  line-height: 150%;
  margin-top: 16px;
  white-space: pre-wrap;
}

.button{
  margin-top: 32px;
}
