.OrgUserListUnitBase {
  box-sizing: border-box;
  padding: 8px 0;
}

.OrgUserListUnitUser{
  align-items: center;
  display: flex;
  justify-content: space-between;
}

