@import '../../scssUtils/variables';

.OrgFCListUnitBase {
  box-sizing: border-box;
  padding: 8px 0;
}

.OrgFCListUnitUser{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.OrgFCListUnitTime{
  color: $color-gray;
  font-size: 1.2rem;
}

.OrgFCListUnitComment{
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 16px;
  cursor: pointer;
}

.OrgFCListUnitPhoto{
  border-radius: 16px;
  height: 128px;
  margin-top: 16px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: .7;
  }
  @media screen and (min-width: 768px) {
    height: 192px;
  }
}

.PaidFCPhotos {
  filter: blur(10px);
  margin: -10px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.FreeFCPhotos{
  height: 100%;
  width: 100%;
}

.OrgFCListUnitVideo{
  align-items: center;
  border-radius: 16px;
  display: flex;
  height: 128px;
  justify-content: center;
  margin-top: 16px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: .7;
  }
  @media screen and (min-width: 768px) {
    height: 192px;
  }
}

.OrgFCListUnitVideoCover{
  background: $color-dark;
  height: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1;
}

.OrgFCListUnitVideoButton{
  z-index: 2;
}

.OrgFCListUnitSound{
  margin-top: 16px;
}

.likeFollowWrap{
  display: flex;
  padding-top: 8px;

  .likeFollowItem{
    margin-right: 16px;
  }
}

.IconLock{
  display: inline-block;
  height: 18px;
  width: 20px;
  margin-left: 3px;
  vertical-align: top;

  span {
    background-size: 13px;
  }

}
