@import '../common/scssUtils/variables';
@import '../common/scssUtils/mixins';

.containerWrap{
  @include containerWraper;
}

.contentWrap{
  border-top: solid 1px $color-white-dark;
  padding: 16px 8px;
}

.contentTitle{
  padding:16px 0;
}

.OrgContentAddCategoryInputContainer{
  display: flex;
  height: 42px;
  margin-bottom: 24px;
}

.OrgContentAddCategoryInput{
  flex: 1;
}

.OrgContentAddCategoryButton{
  height: 42px;
  margin-left: 8px;
  width: 100px;
}

.OrgContentAddCategoryItem{
  box-sizing: border-box;
  display: inline-block;
  margin-top: 8px;
}

.cancelWrapper{
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

.cancel{
  display: inline-block;
}
