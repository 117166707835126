.ButtonBase {
  font-weight: bold;
  height: 40px;
  width: 40px;

  button {
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
}

.ButtonPrimary {
  button {
    background: url('../../assets/images/icon-dialog-off.svg') no-repeat center
      center;
    background-size: 40px;
  }
}

.ButtonSecondary {
  button {
    background: url('../../assets/images/icon-dialog-on.svg') no-repeat center
      center;
    background-size: 40px;
  }
}
