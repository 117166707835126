@import '../../scssUtils/variables';

.McsTextAreaBase {
  background: #f6f6f6;
  border: 1px solid #eee;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 16px;
}

.McsTextAreaTitle{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.McsTextAreaBox{
  background: none;
  border: 0;
  color: $color-dark-light;
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.8;
  min-height: 250px;
  overflow-y: scroll;
  padding-right: 12px;
  resize: none;
  width: calc(100% - 12px);
}
