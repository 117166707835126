@import './components/common/scssUtils/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  background:transparent;
  border:0;
  font-size:100%;
  margin:0;
  outline:0;
  padding:0;
  vertical-align:baseline;
}

body {
  line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display:block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes:none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content:'';
  content:none;
}

a {
  background:transparent;
  font-size:100%;
  margin:0;
  padding:0;
  vertical-align:baseline;
}

/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  border:0;
  border-top:1px solid #ccc;
  display:block;
  height:1px;
  margin:1em 0;
  padding:0;
}

input, select {
  vertical-align:middle;
}

button, input, select, textarea {
  font-family : inherit;
  font-size   : 100%;
}

img {
  font-size: 0;
  line-height: 0;
  vertical-align: top;
}

*:focus {
  outline: none;
}

html {
  color: $color-dark;
  font-family: $sans-serif;
  font-size: 62.5% !important;
  height: 100%;
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "palt";
}

.noScroll{
  height: 100%;
  overflow: hidden !important;
}
