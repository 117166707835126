@import '../../scssUtils/variables';

.OrgContentMovieBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.OrgContentMovieThumbnail{
  background-size: cover;
  width: 100%;
}

.OrgContentMovieThumbnailCover{
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}


.OrgContentMovieButton{
  left: 50%;
  margin: auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.OrgContentMovieVideo{
  height: 0;
  position: absolute;
  z-index: -1999;
}
