@import '../../scssUtils/variables';

.OrgContentPhotoBase {
}

.OrgContentPhotoListWrapper {
  box-sizing: border-box;
  display: block;
}

.OrgContentPhotoList {
  -webkit-appearance: none;
  box-sizing: inherit;
  display: flex;
  list-style-type: none;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.OrgContentPhotoList::-webkit-scrollbar {
  display: none;
}

.OrgContentPhotoListItem {
  align-items: center;
  -webkit-appearance: none;
  background: #fcfcfc;
  box-sizing: inherit;
  display: flex;
  flex-shrink: 0;
  font-size: 100px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  scroll-snap-align: start;
  text-align: -webkit-match-parent;
  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.5s;
  width: 100%;
}

.OrgContentPhoto {
  background-size: cover;
  width: 100%;
}

.OrgContentPhotoPointReaderContainer {
  align-items: center;
  box-sizing: border-box;
  margin-top: 16px;
  width: 100%;
}

.OrgContentPhotoPointReaderList {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
}

.OrgContentPhotoPointReaderActive {
  align-items: center;
  background: linear-gradient(92.01deg, #eb5757 0%, #ff8d23 100%);
  border-radius: 50%;
  box-sizing: border-box;
  height: 8px;
  width: 8px;
}

.OrgContentPhotoPointReaderInactive {
  align-items: center;
  background: #eee;
  border-radius: 50%;
  box-sizing: border-box;
  height: 8px;
  width: 8px;
}
