@import '../../scssUtils/variables';

.OrgSortModalBase {
  background: $color-white;
  border-radius: 4px;

  /* ds-pannel */
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.OrgSortModalItem {
  box-sizing: border-box;
  color: $color-gray;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 16px 14px 16px 32px;
  position: relative;
}

.OrgSortModalItemAct {
  box-sizing: border-box;
  color: $color-dark-light;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 16px 14px 16px 32px;
  position: relative;

  &::after {
    border-bottom: solid 1px $color-blue;
    border-right: solid 1px $color-blue;
    content: '';
    display: block;
    height: 10px;
    left: 15px;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    transform: rotate(35deg);
    width: 6px;
  }
}
