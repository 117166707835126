@import '../../scssUtils/variables';

.OrgContentAddCategoryBase {
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.OrgContentAddCategoryOpen {
  width: 100px;
}

.OrgContentAddCategoryInputContainer {
  display: flex;
  height: 42px;

}

.OrgContentAddCategoryInput {
  flex: 1;
}

.OrgContentAddCategoryButton {
  height: 42px;
  margin-left: 8px;
  width: 96px;
}


.closeWrapper{
  margin-top: 12px;
  text-align: center;
}

.close{
  display: inline-block;
}
