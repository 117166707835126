@import '../common/scssUtils/variables';
@import '../common/scssUtils/mixins';




.containerWrap{
  @include containerWraper;

  overflow-x: hidden;
  width: 100vw;
}


.contentTitle{
  padding:16px 0;
}

.contentTabMenu{
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.contentTabMenuItem{
  width: 25%;
}

.contentButtonWrap{
  padding: 8px 8px;
}

.contentButtonItem{
  margin-top: 8px;
}

.contentSelectWrap{
  box-sizing: border-box;
  display: flex;
  padding: 8px 8px;
  width: 100%;
}

.contentSelectItem{
  padding: 0 0 0 8px;
  width: 50%;

  &:first-child{
    padding: 0;
  }
}

.contentListTitle{
  font-size: 1.4rem;
  font-weight: bold;
  padding: 16px 8px 8px;
}

.contentListWrap{
}

.contentList{
  border-top: solid 1px $color-white-dark;
  box-sizing: border-box;
  padding: 8px;

  &:last-child{
    border-bottom: solid 1px $color-white-dark;
  }
}
