@import '../common/scssUtils/mixins';

.userWrap{
  @include containerWraper;

  padding-bottom: 0;
}

.tabMenuWrap{
  border-bottom: solid 1px $color-white-dark;
  display: flex;
}

.tabMenuItem{
  width: 50%;
}

.contentWrap{
  @include contentWrap;

  padding-bottom: 0;
}

.sectionWrap{

}

.OrgFollowLikeFCList{
  margin-bottom: 0;
  padding-top: 0;
}

.OrgTimeLine{
  border-bottom: solid 1px $color-white-dark;
}
