@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.containerWrap{
  padding-top: 72px;
}

.contentWrap{
  padding: 0 16px;
}

.title{
  margin-bottom: 16px;
  margin-top: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 24px 16px 16px;

  p{
    color: $color-dark-light;
  }
}

.buttonWrap{
  margin-top: 32px;
}

.button{
  margin-bottom: 16px;
  margin-top: 40px;
}

.FanclubListContainer{
  border-top: 1px solid $color-white-dark;
}

.FanclubWrapper{
  border-bottom: 1px solid $color-white-dark;
  padding: 16px 0 16px 16px;
}

.body{
  border-top: 1px solid $color-white-dark;
  padding: 16px 16px 0;
}

.completeContainer{
  padding: 72px 16px 16px;
}

.completeTitle{
  color: $color-dark;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.completeRead{
  color: $color-dark-light;
  font-size: 14px;
  line-height: 150%;
  text-align: center;

}
