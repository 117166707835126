.ButtonBase {
  border-radius: 50%;
  overflow: hidden;
  transition: all .4s ease;
  &:hover{
    opacity: .7;
  }
  a{
    display: block;
    height: 100%;
    text-indent: -9999px;
    width: 100%;
  }
}

.ButtonTwitter{
  background: url('../../assets/images/icon-twitter-button.svg') no-repeat center center;
  background-size: 48px;
}

.ButtonFaceBook{
  background: url('../../assets/images/icon-facebook-button.svg') no-repeat center center;
  background-size: 48px;
}

.ButtonLine{
  background: url('../../assets/images/icon-line-button.svg') no-repeat center center;
  background-size: 48px;
}

.ButtonSizePrimary{
  height: 48px;
  width: 48px;
}
