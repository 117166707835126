@import '../../scssUtils/variables';

.OrgFCListBase {
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  padding: 16px 0 0;
}

.OrgFCListTitle{
  margin-bottom: 16px;
}

.OrgFCListWrap{
  li{
    margin-bottom: 8px;
  }
}
