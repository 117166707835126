@import '../../scssUtils/variables';

.OrgHeroBase {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
}

.OrgHeroSub{
  box-sizing: border-box;
  padding: 0 16px;
}

.OrgHeroMain{
  position: relative;
}

.OrgHeroImage{
  height: 128px;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 192px;
  }
}

.OrgHeroFollowButton{
  max-width: 72px;
  position: absolute;
  right: 16px;
  top:84px;
  width: 100%;
}

.OrgHeroThumbnail{
  margin-left: 16px;
  margin-top: -16px;
}


.OrgHeroFollowList{
  margin-top: 16px;
}

.OrgHeroData{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.OrgHeroDataPlace{
  color: $color-gray;
  font-size: 1.6rem;

  span{
    display: inline-block;
    padding-left: 8px;
  }
}

.OrgHeroDataFollow{
  color: $color-gray;
  font-size: 1.6rem;

  span{
    color: $color-red;
    font-weight: bold;
  }
}

.OrgHeroDescription{
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 16px;
}
