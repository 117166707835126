.McsSNSButtonsBase {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap:wrap;
  margin: 40px 0px;

  h1{
    text-align: center;
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  div{
    margin: 0px 8px;
  }
}
