@import '../../../common/scssUtils/mixins';

.OrgOnBoardingWrap{
  height: 100%;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 2888
}

.OrgOnBoardingContent{
  margin-top: 5vh;
  overflow:auto;
  position: relative;
  width: 100%;
  z-index: 2;
}

.OrgOnBoardingContentItem{
  border-radius: 16px;
  color: #fff;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  img{
    border-radius: 16px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    display: block;
    height: 65vh;
    margin: 0 auto;
    overflow: hidden;
    max-height: 650px;
  }
}

.OrgOnBoardingNav{
  display: flex !important;
  justify-content: center;
  margin-top: 16px;
  position: relative;
  z-index: 2;

  li{
    display: block;
    height: 10px;
    margin: 0 6px;
    width: 10px;

    &:first-child{
      margin-left: 0;
    }

    button{
      background: $color-gray;
      border: none;
      border-radius: 50%;
      font-size: 0;
      height: 12px;
      width: 10px;
    }
  }

}

.OrgOnBoardingNavItem{
  display: block;
  height: 10px;
  margin: 0 6px;
  width: 10px;

  &:first-child{
    margin-left: 0;
  }

  span{
    background: $color-gray;
    border-radius: 50%;
    display: block;
    display: inline-block;
    height: 10px;
    width: 10px;
  }
}

.OrgOnBoardingNavItemAct{
  display: block;
  height: 10px;
  margin: 0 6px;
  width: 10px;

  &:first-child{
    margin-left: 0;
  }

  span{
    @include gradationColor;

    border-radius: 50%;
    display: block;
    display: inline-block;
    height: 10px;
    width: 10px;
  }

}

.OrgOnBoardingButton{
  margin-top: 16px;
  left: 50%;
  margin-left: -96px;
  position: absolute;
  width: 192px;
  z-index: 2;
}

.OrgOnBoardingBg{
  background: rgba($color: #fff, $alpha: 0.75);
  height: 100%;
  position: absolute;
  top:0;
  width: 100%;
  z-index: 1;
}
