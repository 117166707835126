@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.InputBase {
  input{
    -webkit-appearance:none;
    border: none;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    box-sizing: border-box;
    font-size: 1.6rem;
    height: 100%;
    padding: 0 8px;
    width: 100%;
  }
}

.InputPrimary{
  height: 40px;

  input{
    border: solid 1px $color-gray-light-ccc;

    &::placeholder{
      color: $color-gray;
    }

    &:disabled{
      color: $color-gray;
    }
  }
}

.InputSecondary{
  height: 42px;
  position: relative;

  input{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColor;
  }
}

.InputTertiary{
  height: 42px;
  position: relative;

  input{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color:  $color-gray-light-ccc;
    }
  }

  &::after{
    background: $color-dark-light;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}




.InputQuaternary{
  input{
    background: none;
    border: solid 1px $color-gray;
    color: $color-gray;
  }
}

.InputQuinary{
  height: 42px;
  position: relative;

  input{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    background: $color-dark-light;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::before{
    border-right: solid 2px $color-dark-light;
    border-top: solid 2px  $color-dark-light;
    content: '';
    height: 6px;
    margin-top: -6px;
    position: absolute;
    right: 8px;
    top:50%;
    transform: rotate(135deg);
    width: 6px;
  }
}

.InputDisabled{
  &::after{
    background: $color-gray-light-ccc;
  }

  &::before{
    border-right: solid 2px  $color-gray-light-ccc;
    border-top: solid 2px  $color-gray-light-ccc;
  }
}

.InputError{
  input{
    background: $color-red-light;
    border: solid 1px $color-red;
    color: $color-red;

    &::placeholder{
      color: $color-red;
    }
  }
}

.InputErrorWithOutBorder{
  input{
    border: none;
  }
}

.InputErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}

.InputSenary{
  height: 42px;
  position: relative;

  input{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColorSecondary;
  }
}

.InputSeptenary{
  border-radius: 4px;
  height: 40px;
  position: relative;

  input{
    background: $color-gray-light;
    border: none;

    &::placeholder{
      color: $color-gray;
    }
  }

}
