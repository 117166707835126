@import '../../scssUtils/variables';

.ButtonBase {
  font-weight: bold;
  height: 48px;
  width: 48px;

  button{
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    position: relative;
    text-indent: -9999px;
    width: 100%;
  }
}

.ButtonOpen{
  button{
    span{
      background: $color-dark;
      display: block;
      height: 2px;
      left: 50%;
      margin-left: -12px;
      position: absolute;
      width: 24px;

      &:nth-child(1){
        top:17px;
      }

      &:nth-child(2){
        top: 23px;
      }

      &:nth-child(3){
        top: 29px;
      }
    }
  }
}

.ButtonClose{
  background: $color-red;

  span{
    background: $color-white;
    display: block;
    height: 2px;
    left: 50%;
    margin-left: -12px;
    position: absolute;
    top:50%;
    width: 24px;

    &:nth-child(1){
      transform: rotate(45deg);
    }

    &:nth-child(2){
      display: none;
    }

    &:nth-child(3){
      transform: rotate(-45deg);
    }
  }
}
