.OrgUserListUnitBase {
  box-sizing: border-box;
  padding: 8px 0;
  position: relative;
}

.OrgUserListUnitContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.OrgUserListUnitUser {
  align-items: center;
  display: flex;
}

.OrgUserListUnitLabel {
  margin-left: 22px;
}

.OrgUserListUnitButton {
}

.OrgUserListUnitDialog {
  position: absolute;
  right: 4px;
  top: 12px;
  width: 160px;
  z-index: 2000;
}

.OrgUserListUnitDialogBg {
  background-color: red;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1999;
}
