@import '../common/scssUtils/variables';
@import '../common/scssUtils/mixins';




.container{
  @include containerWraper;
}

.title{
  padding: 16px 0;
}

.contentTabMenu{
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.contentTabMenuItem{
  width: 25%;
}

.contentWrap{
  border-top: solid 1px $color-white-dark;
  padding: 16px 8px;
}

.contentItem{
  margin-bottom: 16px;
}

.contentItemSecondary{
  margin-bottom: 24px;
}

.contentCategoryTagList{
  border-top: solid 1px $color-white-dark;
  padding-left: 8px;
  padding-top: 8px;
}

.contentCategoryTag{
  margin-bottom: 8px;
  margin-right: 8px;
}

.contentCategoryButton{
  width: 88px;
}

.contentTextArea{
  height: 100%;
  margin-bottom: 16px;

  textarea{
    height: 320px;
  }
}

.contentDeleteButton{
  left: 8px;
  position: absolute;
  top: 8px;
  z-index: 1;
}

.contentImageWrapper{
  position: relative;
  & + & {
    margin-top: 10px;
  }
}

.contentButtonWrap{
  padding: 8px;
  text-align: center;
}

.contentButtonItem{
  margin-top: 8px;
}

.cancel{
  display: inline-block;
}

.contentConfirmImage{
  width: 100%;

  img{
    height: auto;
    width: 100%;
  }
}

.contentConfirmTitle{
  font-size: 2rem;
  line-height: 1.4;
  overflow-wrap: break-word;
  padding: 8px;
  word-wrap: break-word;
}
.contentConfirmTag{}
.contentConfirmTagItem{}


.contentConfirmBody{
  font-size: 1.4rem;
  line-height: 1.4;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word;
  iframe{
    max-width: 100%;
  }
}

.contentConfirmDateArea{
  margin-bottom: 16px;

  dt{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 8px;
  }

  dd{
    font-size: 1.4rem;
  }
}

.contentConfirmBodyPhotos {
  margin-top: 32px;
}
