@import '../../scssUtils/variables';

.OrgAdminListLikeUnitBase {
  box-sizing: border-box;
}

.OrgAdminListLikeUnitMainContent{
  display: flex;
}

.OrgAdminListLikeUnitUserImage{
  margin-right: 8px;
  position: relative;
}

.OrgAdminListLikeUnitUserText{
  margin-bottom: 8px;
  width: calc(100vw - 88px);
}

.OrgAdminListLikeUnitSubContent{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.OrgAdminListLikeUnitTag{
  display: flex;
  flex-wrap: wrap;
}

.OrgAdminListLikeUnitTagItem{
  margin-bottom: 4px;
  margin-right: 4px;
}

.OrgAdminListLikeUnitInfo{
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  width: 150px;
}

.OrgAdminListLikeUnitCount{
  left: 0%;
  min-width: 16px;
  padding: 0 1px;
  position: absolute;
  top:0%;
}
