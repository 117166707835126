@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.base{

}

.label{
  font-size: 15px;
  font-weight: bold;
  margin-top: 24px;
}

.text{
  color: $color-dark-light;
  font-size: 14px;
  line-height: 150%;
  margin-top: 8px;
}

.cardNumberContainer{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
