.Wrap{
  background: #e5e5e5;
  box-sizing: border-box;
  padding: 8px 16px 24px;
  width: 100%;
}

.Section{
  box-sizing: border-box;
  padding: 0 0 0;
  width: 100%;
}

.Note{
  color: #999;
  font-size: 1.2rem;
  padding-top: 8px;
}

.h2{
  font-size: 1.8rem;
  font-weight: bold;
  padding-top: 16px;
}

.p{
  font-size: 1.4rem;
  font-weight: bold;
  margin:0 0 24px;
}

.hr{
  margin: 24px 0;
}
