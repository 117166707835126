.IconBase {
  span {
    display: block;
    height: 100%;
    position: relative;
    width: 100%;
  }
}

.IconStar {
  height: 56px;
  width: 56px;

  span {
    background: url('../../assets/images/icon-star.svg') no-repeat center center;
    background-size: 20px;
  }
}

.IconFavoriteWideLine {
  height: 56px;
  width: 56px;

  span {
    background: url('../../assets/images/icon-favorite-wide-line.svg') no-repeat
      center center;
    background-size: 18px 18px;
  }
}

.IconFavoriteLine {
  height: 24px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-favorite-line.svg') no-repeat
      center center;
    background-size: 16px 14px;
  }
}

.IconFavoriteFill {
  height: 24px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-favorite-fill.svg') no-repeat
      center center;
    background-size: 16px 14px;
  }
}

.IconSpeechBubble {
  height: 24px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-speech-bubble.svg') no-repeat
      center center;
    background-size: 18px;
  }
}

.IconCamera {
  height: 27px;
  width: 27px;

  span {
    background: url('../../assets/images/icon-camera.svg') no-repeat center
      center;
    background-size: 27px 24px;
  }
}

.IconVoice {
  height: 27px;
  width: 27px;

  span {
    background: url('../../assets/images/icon-voice.svg') no-repeat center
      center;
    background-size: 27px 24px;
  }
}

.IconVoiceGray {
  height: 27px;
  width: 27px;

  span {
    background: url('../../assets/images/icon-voice-gray.svg') no-repeat center
      center;
    background-size: 27px 24px;
  }
}

.IconPen {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-pen.svg') no-repeat center center;
    background-size: 22px 22px;
  }
}

.IconPenWhite {
  height: 24px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-pen-white.svg') no-repeat center center;
    background-size: 24px 24px;
  }
}

.IconMail {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-mail.svg') no-repeat center center;
    background-size: 23px 18px;
  }
}

.IconFavoriteLinePink {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-favorite-line-pink.svg') no-repeat
      center center;
    background-size: 20px 18px;
  }
}

.IconSpeechBubblePurple {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-speech-bubble-purple.svg')
      no-repeat center center;
    background-size: 25px 23px;
  }
}

.IconSetting {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-setting.svg') no-repeat center
      center;
    background-size: 20px 20px;
  }
}

.IconPerson {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-person.svg') no-repeat center
      center;
    background-size: 20px 24px;
  }
}

.IconPersonWithHeart {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-person-heart.svg') no-repeat center
      center;
    background-size: 24px 28px;
    margin-left: 2px;
  }
}

.IconSales {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-sales.svg') no-repeat center
      center;
    background-size: 24px 24px;
  }
}

.IconAdmin {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-admin.svg') no-repeat center
      center;
    background-size: 28px 28px;
  }
}

.IconTrash {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-trash.svg') no-repeat center
      center;
    background-size: 24px 24px;
  }
}

.IconBell {
  height: 48px;
  width: 48px;

  span {
    background: url('../../assets/images/icon-bell.svg') no-repeat center
      center;
    background-size: 22px 26px;
  }
}

.IconDelate {
  height: 24px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-delete.svg') no-repeat center
      center;
    background-size: 13px 14px;
  }
}

.IconPMark {
  height: 16px;
  width: 16px;

  span {
    background: url('../../assets/images/icon-p.svg') no-repeat center center;
    background-size: 16px 16px;
  }
}

.IconSort {
  height: 15px;
  width: 24px;

  span {
    background: url('../../assets/images/icon-sort.svg') no-repeat center center;
    background-size: 24px 15px;
  }
}

.IconFcHomeOn {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-home-on.svg') no-repeat
      center center;
    background-size: 24px 24px;
  }
}

.IconFcHomeOff {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-home-off.svg') no-repeat
      center center;
    background-size: 24px 24px;
  }
}

.IconFcTwOn {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-tw-on.svg') no-repeat
      center center;
    background-size: 27px 22px;
  }
}

.IconFcTwOff {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-tw-off.svg') no-repeat
      center center;
    background-size: 27px 22px;
  }
}

.IconFcFbOn {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-fb-on.svg') no-repeat
      center center;
    background-size: 13px 26px;
  }
}

.IconFcFbOff {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-fb-off.svg') no-repeat
      center center;
    background-size: 13px 26px;
  }
}

.IconFcIgOn {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-ig-on.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }
}

.IconFcIgOff {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-fc-link-ig-off.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }
}

.IconTwitterWhite {
  height: 18px;
  width: 22px;

  span {
    background: url('../../assets/images/icon-twitter-white.svg') no-repeat
      center center;
    background-size: 22px 18px;
  }

}

.IconChatYellow{
  span {
    background: url('../../assets/images/icon_chat_yellow.svg') no-repeat
      center center;
    background-size: 24px;
  }
}

.IconChat{
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon_chat.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }

}

.IconChatActive{
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon_chat_active.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }
}

.IconTimeline{
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon_timeline.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }
}

.IconTimelineActive{
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon_timeline_active.svg') no-repeat
      center center;
    background-size: 26px 26px;
  }
}

.IconLock {
  height: 28px;
  width: 28px;

  span {
    background: url('../../assets/images/icon-lock.svg') no-repeat center
      center;
    background-size: 26px 26px;
  }
}
