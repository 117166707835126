@import '../../scssUtils/mixins';

.base {
  overflow: hidden;
  padding:  0;

  @include panelBackGround;
}

.unit{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

}

.unit2{
  align-items: center;
  background-color: $color-gray-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px
}

.price{
  font-family: 'Roboto Condensed', $sans-serif;
  font-size: 64px;
  font-weight: bold;
  margin-top: 8px;

  span {
    font-family: $sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
}

.description{
  color: $color-dark-light;
  font-size: 12px;
  line-height: 150%;
  white-space: pre-wrap;
}
