@import '../../scssUtils/variables';

.CopyRightBase {
  height: 48px;
  text-align: center;
  width: 100%;

  small{
    color: $color-dark-light;
    font-size: 1rem;
    line-height: 48px;
  }
}
