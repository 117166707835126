@import '../common/scssUtils/mixins';

.containerWrap{
  padding-top: 72px;
}

.contentWrap{
  padding: 0 16px;
}

.title{
  margin-bottom: 16px;
  margin-top: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 24px 16px 16px;

  p{
    color: $color-dark-light;
  }
}

.buttonWrap{
  margin-top: 32px;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.cancel{
  display: inline-block;
}
