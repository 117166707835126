@import '../../../common/scssUtils/mixins';

.OrgFanclubListModalNavWrap{
  height: 100%;
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  z-index: 2888
}

.OrgFanclubListModalNavContent{
  height: calc(100% - 80px);
  left: 16px;
  overflow: auto;
  position: fixed;
  top:48px + 16px;
  width: calc(100% - 32px);
  z-index: 888;
}

.OrgFanclubListModalNavBg{
  background: rgba($color: #000, $alpha: 0.8);
  height: 100%;
  position: fixed;
  top: 48px;
  width: 100%;
  z-index: 887;
}
