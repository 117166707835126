.OrgFCListUnitBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.OrgFCListUnitUser{
  width: 100%;
}

.OrgFCListUnitFollowButton{
  position: absolute;
  right: 16px;
  z-index: 2;

  button{
    padding: 0 12px;
    white-space: nowrap;
  }
}
