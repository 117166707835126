@import '../common/scssUtils/mixins';

.homeWrap{
  @include containerWraper;

  padding-bottom: 0;
}

.headButtonWrap{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 8px 4px;
}

.headButtonItem{
  margin: 0 4px;
  width: 100%;
}

.contentWrap{
  @include contentWrap;
}
