@import '../../scssUtils/variables';

.OrgHeroBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 128px;
  padding: 0 15px;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    height: 192px;
  }
}


.OrgHeroTitle{
}

.OrgHeroTitlePickUpContainer{
  background-color: #fff;
  border: 2px solid #eb5757;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 5px;
  padding: 4px 8px;
}

.OrgHeroTitlePickUp{
  color: #eb5757;
  font-family: Raleway;
  font-size: 8px;
  font-weight: 900;
}

.OrgHeroTitleMain{
  margin-bottom: 8px;
  text-shadow: 0 0 4px #fff;
}

.OrgHeroTitleSub{
  font-size: 1rem;
  text-shadow: 0 0 2px #fff;
}
