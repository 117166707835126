@import '../../scssUtils/variables';

.IconBase {
  border-radius: 2px;
  box-sizing: border-box;
  overflow: hidden;

  img{
    background-color: $color-white;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.IconPrimary{
}

.IconSecondary{
}


.IconSizePrimary{
  height: 72px;
  width: 72px;
}

.IconSizeSecondary{
  height: 64px;
  width: 64px;
}

.withBlur{
  filter: blur(5px);
  margin: -5px;
  width: calc(100% + 10px);
  height:calc(100% + 10px);
}

.noBlur{
  width: 100%;
  height:100%;
}
