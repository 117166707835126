@import '../common/scssUtils/mixins';

.containerWrap{
  @include container;

  padding-left: 0;
  padding-right: 0;
}

.contentTitle{
  border-bottom: 1px solid $color-white-dark;
  padding: 16px 0;
}

.title{
  font-size: 15px;
  margin-bottom: 16px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;


  p{
    color: $color-dark-light;
    margin-bottom: 24px;
  }

  span{
    color: $color-dark;
    font-weight: bold;
  }
}

.content{
  border-bottom: 1px solid $color-white-dark;
  padding-left: 8px;
  padding-right: 8px;
}

.contentWrap{
  margin-bottom: 24px;
}

.buttonWrap{
  margin-top: 32px;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.addContainer{
  padding: 24px 8px;
}

.cancel{
  display: inline-block;
}
