@import '../../scssUtils/variables';

.OrgFollowerAdminNavBase {
  background: $color-white;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
  position: relative;
  width: 100%;
}

.OrgFollowerAdminNavTitle {
  align-items: center;
  display: flex;

  p {
    color: $color-gray;
    font-size: 1rem;
    margin-left: 4px;
  }
}

.OrgFollowerAdminNavList {
  align-items: center;
  display: flex;

  div {
    margin-left: 20px;
  }
}

.OrgFollowerAdminNavDialog {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2000;
}

.OrgFollowerAdminNavDialogBg {
  background-color: red;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1999;
}
