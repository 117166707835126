@import '../../scssUtils/variables';

.McsJasracBase {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  max-width: 768px;
  margin: 24px auto;
  color: $color-gray;
  vertical-align: top;
}

.McsJasracLogo{
  display: inline-block;
  vertical-align: middle;
}

.McsJasracRead{
  padding-left: 8px;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.4;
}
