@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.base {
  overflow: hidden;
  padding:  0;

  @include panelBackGround;
}

.unit{
  padding: 16px;

}

.unit2{
  background-color: $color-gray-light;
  padding: 16px
}
