@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.containerWrap{
  @include container;

  padding-top: 48px;

}

.pleaseLoginContainer{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

}

.pleaseLoginTitle{
  color: $color-red;
  font-size: 20px;
  font-weight: bold;
}

.pleaseLoginDetails{
  color: $color-dark-light;
  font-size: 14px;
  line-height: 150%;
  margin-top: 16px;
  white-space: pre-wrap;
}

.signup{
  margin-top: 32px;
}
