@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.IconBase {
  align-items: center;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-left: -2px;

  img{
    height: calc(100% - 4px);
    object-fit: cover;
    width: calc(100% - 4px);
    border-radius: 50%;
  }
}

.IconSizePrimary{
  height: 52px;
  width: 52px;
}

.IconSizeSecondary{
  height: 44px;
  width: 44px;
}

.IconSizeTertiary{
  height: 40px;
  width: 40px;
}

.IconSizeQuaternary{
  height: 36px;
  width: 36px;
}

.IconSizeQuinary{
  height: 34px;
  width: 34px;
}

.IconSizeSenary{
  height: 20px;
  width: 20px;
}

.IconSizeSeptenary{
  height: 84px;
  width: 84px;
}

.borderWhite{
  background-color: $color-white;
}

.borderRed{
  background-color: $color-red;
}

.borderGrade{
  @include gradationColor;
}
