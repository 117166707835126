@import '../../scssUtils/variables';

.McsTitleBase {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
}

.McsTitleTitle{
  font-size: 1.4rem;
}
