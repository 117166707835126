@import '../../scssUtils/variables';

.OrgEmbeddedLoadingBase {
  align-items: center;
  // background: $color-dark;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0.5;
  // position: fixed;
  top:0;
  width: 100%;
  z-index: 3000;
}

.OrgEmbeddedLoadingIcon{
  background: url('../../assets/images/embedded-oval.svg') no-repeat
    center center;
  height: 100%;
  position: relative;
  width: 100%;
}
