@import '../common/scssUtils/mixins';

.containerWrap{
  @include container;

  padding: 72px 0 24px;
}

.contentTitle{
  padding: 16px 0;
}

.contentWrap{
  margin: 0 8px;
}

.blockListContainer{
  border-top: solid 1px #eee;
}

.blockWrapper{
  border-bottom: solid 1px #eee;
}
