@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.OrgHeaderNavBase {
  overflow: hidden;
  padding:  0;

  @include panelBackGround;
  max-width: 768px;
  margin: auto;
}

.OrgHeaderNavUser{
  align-items: center;
  border-bottom: solid 1px $color-white-dark;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px;
}

.OrgHeaderNavLinks{
  background:$color-gray-light ;

  li{
    box-sizing: border-box;
    color: $color-dark-light;
    font-size: 1.3rem;
    height: 45px;
    line-height: 45px;
    padding: 0 16px;
    width: 100%;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
    a{
      color: $color-dark-light;
      display: block;
      height: 100%;
      text-decoration: none;
      width: 100%;
    }
  }
}
