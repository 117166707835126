@import '../../scssUtils/variables';


.base {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.tag{
  background-color: $color-gray;
  color: $color-white;
  font-weight: bold;
  padding: 4px 8px;
}

.text{
  font-size: 14px;
  margin-left: 10px;
}
