@import '../common/scssUtils/mixins';

.container{
  @include containerWraper;

  padding: 16px;
  padding-top: 48px;
}

.title{
  margin-top: 16px;
  text-align: center;
}

.read{
  color: $color-dark-light;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

.product{
  margin-top: 24px;

}

.cardInfo{
  margin-top: 24px;

}

.description{
  margin-top: 32px;

}

.input{
  margin-top: 24px;
}

.checkbox{
  margin-top: 24px;
}

.button{
  margin-top: 24px;

}
