@import '../../scssUtils/variables';

.OrgHeroBase {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  width: 100%;
}

.OrgHeroSub{
  box-sizing: border-box;
  padding: 0 16px;
}

.OrgHeroMain{
  position: relative;
}

.OrgHeroImage{
  height: 128px;
  width: 100%;
  @media screen and (min-width: 768px) {
    height: 192px;
  }
}

.OrgHeroFollowButton{
  max-width: 98px;
  position: absolute;
  right: 16px;
  bottom:80px;
  width: 100%;
}

.OrgHeroThumbnail{
  margin-left: 16px;
  margin-top: -16px;
}

.OrgHeroContent{
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}

.OrgHeroFollowList{
  margin-top: 16px;
}

.OrgHeroData{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.OrgHeroDataPlace{
  color: $color-gray;
  font-size: 1.6rem;

  span{
    display: inline-block;
    padding-left: 8px;
  }
}

.OrgHeroDataFollow{
  color: $color-gray;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all .4s ease;
  span{
    color: $color-red;
    font-weight: bold;
  }
  &:hover{
    opacity: .7;
  }
}

.OrgHeroDescriptionHidden{
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 16px;
  max-height: 4.5em;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.OrgHeroDescriptionOpen {
  animation: open 3s ease-out 0s forwards;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 16px;
  overflow: hidden;
  overflow-wrap: break-word;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@keyframes open {
  0% {
    max-height: 4.5em;
  }

  90% {
    max-height: 400em;

  }

  100%{
    max-height: 100%;
  }
}

.OrgHeroDescriptionCover {
  background: -moz-linear-gradient(top , rgba(255,255,255,0), $color-white);
  background: -webkit-linear-gradient(top , rgba(255,255,255,0), $color-white);
  background: linear-gradient(to bottom, rgba(255,255,255,0), $color-white);
  bottom: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
