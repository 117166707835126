.OrgFCListUnitBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}
