@import '../../scssUtils/variables';

.OrgInviteMemberListBase {
}


.OrgInviteMemberListWrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  li{
    margin-bottom: 8px;
    margin-right: 8px;
  }
}
