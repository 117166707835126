@import '../../scssUtils/variables';

.McsIconTextBase {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  dd{
    color: $color-dark-light;
    font-size: 1.2rem;
    height: 26px;
    line-height: 24px;
  }
}

.McsIconTextColorRed{
  dd{
    color: $color-red;
  }
}

.McsIconTextColorGray{
  dd{
    color: $color-gray;
  }
}
