@import '../common/scssUtils/mixins';

.containerWrap{
  padding: 72px 0 24px;
  max-width: 768px;
  margin: auto;
}

.title{
  margin-bottom: 16px;
  margin-left: 16px;
}

.read{
  color: $color-dark-light;
  font-size: 1.4rem;
  line-height: 1.6;
  margin-left: 16px;

  p{
    margin-bottom: 24px;
  }
}

.contentTitle{
  color: $color-dark;
  font-size: 1.5rem;
  margin-bottom: 16px;
  margin-left: 16px;
}

.checkBoxWrap{
  border-bottom: solid 1px $color-white-dark;
  border-top: solid 1px $color-white-dark;
  padding: 8px 0 0 8px;
}

.checkBox{
  margin-bottom: 8px;
  margin-left: 8px;
}

.button{
  margin: 32px 16px 0;
}
