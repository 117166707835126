@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.OrgUserListBase {
  overflow: hidden;
  padding:  0;
  cursor: pointer;
  @include panelBackGround;

  li{
    border-bottom: solid 1px $color-white-dark;
    height: 56px;
    padding-left: 8px;

    &:last-child{
      border-bottom: none;
    }
  }
}
