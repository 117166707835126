@import '../common/scssUtils/mixins';

.commentWrap{
  @include containerWraper;

  padding-top: 57px;
  padding-bottom: 0;
}

.commentHeader{
  position: fixed;
  top:0;
  z-index: 1000;
}

.userWrap{
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;

  @include contentWrap;
}
