@import '../../scssUtils/variables';

.OrgContentPhotoRegisterBase {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 8px;
}

.OrgContentPhotoRegisterWrapper {
  align-items: center;
  display: flex;
  height: calc((100vw - 8px * 4) / 3);
  justify-content: center;
  margin-bottom: 8px;
  margin-right: 8px;
  width: calc((100% - 8px * 3) / 3);
}

.OrgContentPhotoRegisterInputErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
