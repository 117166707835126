@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.ButtonBase {
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
}

.ButtonPrimary{
  button{
    color: $color-white;
    @include gradationColor;
  }

}

.ButtonSecondary{
  button{
    background: $color-gray-light;

    span{
      @include gradationText;

      font-weight: bold;
    }
  }
}

.ButtonTertiary{
  button{
    background: $color-white;
    border: solid 1px $color-gray-dark;
    color: $color-gray-dark;

  }
}

.ButtonQuaternary{
  button{
    background: none;
    border: solid 1px $color-gray;
    color: $color-gray;

  }
}

.ButtonQuinary{
  button{
    background: none;
    color: $color-gray;

  }
}

.ButtonSenary{
  button{
    color: $color-white;
    font-weight: bold;

    @include gradationColorSecondary;
  }
}

.ButtonSeptenary{
  button{
    background: $color-gray-light;

    span{
      @include gradationTextSecondary;

      font-weight: bold;
    }
  }
}

.ButtonTwitter{
  button{
    background: $color-white;
    border: solid 1px $color-twitter;
    color: $color-twitter;

    span{
      background: url('../../assets/images/icon-twitter.svg') no-repeat left;
      background-size: 22px 18px;
      box-sizing: border-box;
      padding-left: 30px;
    }
  }
}

.ButtonSizePrimary{
  font-size: 1.6rem;
  height: 48px;
  @media screen and (min-width: 768px) {
    height: 64px;
  }

  button{
    border-radius: 4px;
  }
}

.ButtonSizeSecondary{
  font-size: 1.4rem;
  height: 40px;
  @media screen and (min-width: 768px) {
    height: 48px;
  }
  button{
    border-radius: 4px;
  }
}

.ButtonSizeTertiary{
  font-size: 1.2rem;
  height: 22px;
  @media screen and (min-width: 768px) {
    height: 32px;
  }
  button{
    border-radius: 4px;
  }
}
