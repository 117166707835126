@import '../../scssUtils/variables';


.McsIconLabelBase {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}

.McsIconUnLabelBase {
  align-items: center;
  cursor: pointer;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}

.McsIconUnLabelText {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  font-size: 1.2rem;
  -webkit-line-clamp: 2;
  line-height: 1.4;
  margin-top: 15px;
  overflow: hidden;
  text-align: center;
}

.McsIconLabelImage{
  flex-shrink: 0;
  margin-right: 8px;
}

.McsIconLabelReadText{
  -webkit-box-orient: vertical;
  color: $color-dark-light;
  display: -webkit-box;
  font-size: 1.1rem;
  -webkit-line-clamp: 2;
  line-height: 1.4;
  margin-top: 8px;
  overflow: hidden;
}

.McsIconLabelPrimary{
}

.McsIconLabelSecondary{
}
