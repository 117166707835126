@import '../../scssUtils/variables';

.OrgGroupListUnitBase {
  align-items: center;
  background: $color-gray-light;
  border: solid 1px $color-white-dark;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: flex-start;
  padding: 6px 0 6px 18px;
  position: relative;
  width: auto;
}

.OrgGroupListUnitIconTitle{
  color: $color-dark-light;
  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 8px;
}

.OrgGroupListUnitIconList{

}

.OrgGroupListUnitIcon{
  display: inline-block;
  margin-left: -6px;

  &:first-child{
    margin-left: 0;
  }
}

.OrgGroupListUnitArrow{
  position: absolute;
  right: 0;
  top:2px;
}
