@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.OrgUserListUnitBase {
  border-radius: 14px;
  height: 28px;
  position: relative;
  white-space: nowrap;

  @include gradationColorSecondary;
}

.OrgUserListUnitContent {
  align-items: center;
  background: $color-white;
  border-radius: 12px;
  box-sizing: border-box;
  color: $color-blue;
  display: flex;
  font-size: 1.2rem;
  height: 26px;
  justify-content: space-between;
  margin: 0 0 0 1px;
  max-width: calc(100vw - 16px);
  padding: 0 14px;
  transform: translateY(1px);
  width: calc(100% - 2px);
}

.OrgUserListUnitIconClose {
  margin-left: 12px;
}

.OrgUserListUnitName {
  overflow: hidden;
  // text-overflow: ellipsis;
  word-wrap: break-word;
}
