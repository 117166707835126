@import '../../scssUtils/variables';

.OrgFCListBase {
  box-sizing: border-box;
  padding: 16px 0 8px;
}


.OrgFCListTitle{
  margin-bottom: 8px;
  margin-left: 16px;
}

.OrgFCListWrap{
  li{
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 8px;
    padding: 8px;
  }
}
