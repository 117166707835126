@import '../../scssUtils/variables';
@import '../../scssUtils/_mixins';

.McsBannerBase {
  box-sizing: border-box;
  padding: 16px;
  text-align: center;
  width: 100%;

  @include panelBackGround;
}

.McsBannerTitle{
  font-size: 1.5rem;
  font-weight: bold;
}

.McsBannerButton{
  margin-top: 16px;
}
