
.IconBase {
  height: 32px;
  width: 32px;
}

.IconPrimary{
  background: url('../../assets/images/jasrac.png') no-repeat center center;
  background-size: 32px 32px;
}
