@import '../../scssUtils/variables';

.OrgLoaderBase {
  align-items: center;
  background: $color-white;
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 4000;
}

.OrgLoaderIcon{
  animation: LoaderMotionKey 5s;
  animation-iteration-count: infinite;
  animation-timing-function:  cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background: url('../../assets/images/icon_loading.svg') no-repeat
    center center;
  background-size: 91px 92px;
  height: 92px;
  position: relative;
  width: 92px;

  &::after{
    animation: LoaderMotionInnerKey 5s;
    animation-iteration-count: infinite;
    animation-timing-function:  cubic-bezier(0.455, 0.03, 0.515, 0.955);
    background: $color-white;
    border-radius: 50%;
    content: '';
    display: block;
    height: 70px;
    left: 50%;
    margin: -34px 0 0 -34px;
    position: absolute;
    top:50%;
    width: 70px;
  }
}

@keyframes LoaderMotionKey {
  0% {
    transform: rotate(0deg) scale(1);
  }

  35% {
    opacity: 0.9;
    transform: rotate(-5deg) scale(0.85);
  }

  50% {
    transform: rotate(0deg) scale(1);
  }

  85% {
    opacity: 0.9;
    transform: rotate(5deg) scale(0.85);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes LoaderMotionInnerKey {
  0% {
    transform: rotate(0deg) scale(1.07);
  }

  35% {
    // opacity: 0.8;
    transform: rotate(-3deg) scale(1);
  }

  50% {
    transform: rotate(0deg) scale(1.07);
  }

  85% {
    // opacity: 0.8;
    transform: rotate(3deg) scale(1);
  }

  100% {
    transform: rotate(0deg) scale(1.07);
  }
}

.OrgLoaderDone{
  animation: LoaderMotionDoneKey 0.6s;
  animation-fill-mode: forwards;
  animation-timing-function:  cubic-bezier(0.47, 0, 0.745, 0.715);
}

@keyframes LoaderMotionDoneKey {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}


.OrgLoaderIconDone{
  animation: LoaderMotionIconDoneKey 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function:  cubic-bezier(0.47, 0, 0.745, 0.715);
}

@keyframes LoaderMotionIconDoneKey {
  0% {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }

  99% {
    opacity: 0;
    transform: rotate(0deg) scale(1.5);
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
