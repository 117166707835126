@import '../../scssUtils/variables';

.LinkBase {
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
}

.LinkPrimary{
  color: $color-red;
  font-size: 1.4rem;
  text-decoration: none;
}

.LinkSecondary{

}
