@import '../../scssUtils/variables';

.OrgFCListBase {
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 16px 0 0;
  max-width: 768px;
  margin: auto;
}


.OrgFCListTitle{
  border-bottom: solid 1px $color-white-dark;
  padding: 0 0 8px 16px;
}

.OrgFCListWrap{
  padding-left: 16px;
  padding-top: 8px;

  li{
    margin-bottom: 8px;
  }
}
