@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.TextAreaBase {
  min-height: 130px;

  textarea{
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    box-sizing: border-box;
    font-size: 1.6rem;
    height: 100%;
    min-height: 130px;
    padding: 8px;
    resize: vertical;
    width: 100%;
  }
}

.TextAreaPrimary{
  textarea{
    border: solid 1px $color-gray-light-ccc;

    &::placeholder{
      color: $color-gray;
    }

    &:disabled{
      color: $color-gray;
    }
  }
}

.TextAreaSecondary{
  position: relative;

  textarea{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColor;
  }
}

.TextAreaTertiary{
  position: relative;

  textarea{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    background: $color-dark-light;
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.TextAreaQuaternary{
  textarea{
    background: none;
    border: solid 1px $color-gray;
    color: $color-gray;
  }
}

.TextAreaSenary{
  position: relative;

  textarea{
    background: $color-gray-light;

    &::placeholder{
      color: $color-gray-light-ccc;
    }

    &:disabled{
      color: $color-gray-light-ccc;
    }
  }

  &::after{
    bottom: 0;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;

    @include gradationColorSecondary;
  }
}

.TextAreaDisabled{
  &::after{
    background: $color-gray-light-ccc;
  }

  &::before{
    border-right: solid 2px  $color-gray-light-ccc;
    border-top: solid 2px  $color-gray-light-ccc;
  }
}

.TextAreaError{
  textarea{
    background: $color-red-light;
    border: solid 1px $color-red;
    color: $color-red;

    &::placeholder{
      color: $color-red;
    }
  }
}

.TextAreaErrorWithOutBorder{
  textarea{
    border: none;
  }
}

.TextAreaErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
