@import '../../scssUtils/variables';

.OrgUserListUnitBase {
  align-items: center;
  background: $color-gray-light;
  border: solid 1px $color-white-dark;
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 6px 0 6px 8px;
  width: auto;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
    opacity: .7;
  }
}

.OrgUserListUnitLeftComponent{
  align-items: center;
  display: flex;
}

.OrgUserListUnitIconList{

}

.OrgUserListUnitIcon{
  display: inline-block;
  margin-left: -6px;

  &:first-child{
    margin-left: 0;
  }
}

.OrgUserListUnitText{
  color: $color-dark-light;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
}
