.OrgAdminListCommentUnitBase {
  border-top: solid 1px #eee;
  box-sizing: border-box;
  height: 75px;
  padding: 8px;
}

.OrgAdminListCommentUnitInfoContainer {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.OrgAdminListCommentUnitDateTime {
  box-sizing: border-box;
  color: #96a9bc;
  font-size: 12px;
}

.OrgAdminListCommentUnitCommentWrapper {
  box-sizing: border-box;
  margin-right: 50px;
  margin-top: 8px;
}

.OrgAdminListCommentUnitComment {
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  color: #666;
  display: -webkit-box;
  font-size: 12px;
  -webkit-line-clamp: 2;
  line-height: 140%;
  overflow: hidden;
  overflow-wrap: break-word;
}
