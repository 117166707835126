@import '../../scssUtils/variables';

.OrgFollowerAdminBarBase {
  background: $color-white;
  display: flex;
  padding: 10px 0;
  width: 100%;
}

.OrgFollowerAdminBarItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: calc(100% / 3);

  dt {
    color: $color-gray;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;

    div {
      margin: 0 auto;
    }
  }

  dd {
    font-size: 2.4rem;
    font-weight: bold;

    span {
      font-size: 1.2rem;
      font-weight: normal;
    }
  }
}
