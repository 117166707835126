@import '../../scssUtils/variables';

.OrgUserListUnitBase {
  align-items: center;
  background: $color-gray-light;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  height: 24px;
  justify-content: flex-start;
  padding: 6px 6px;
  width: auto;
}
