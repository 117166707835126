@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.ButtonBase {
  font-weight: bold;
  text-align: center;
  width: 100%;

  button{
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
}

.ButtonPrimary{
  button{
    color: $color-white;

    @include gradationColor;
  }

}

.ButtonSecondary{
  button{
    background: $color-gray-light;

    span{
      @include gradationText;

      font-weight: bold;
    }
  }
}

.ButtonTertiary{
  button{
    background: $color-white;
    border: solid 1px $color-gray-dark;
    color: $color-gray-dark;
  }
}

.ButtonQuaternary{
  button{
    background: none;
    border: solid 1px $color-gray;
    color: $color-gray;
  }
}

.ButtonQuinary{
  button{
    color: $color-white;

    @include gradationColorSecondary;
  }
}

.ButtonSenary{
  button{
    background: $color-gray-light;

    span{
      @include gradationTextSecondary;

      font-weight: bold;
    }
  }
}




.ButtonSizePrimary{
  font-size: 1.6rem;
  height: 48px;

  button{
  }
}

.ButtonSizeSecondary{
  font-size: 1.4rem;
  height: 40px;

  button{
  }
}

.ButtonSizeTertiary{
  font-size: 1.2rem;
  height: 22px;

  button{
  }
}
