@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.base {
}

.label{
  margin-bottom: 8px;
}

.input{
  margin-top: 24px;
}

.error{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
