@import '../../scssUtils/variables';

.OrgInviteMemberListBase {
  background: #f9f9f9;
  box-sizing: border-box;
}

.OrgInviteMemberListWrap{
  li{
    border-bottom: solid 1px $color-white-dark;

    &:last-child{
      border-bottom: none;
    }
  }
}
