@import '../../scssUtils/variables';

.McsFooterPRBase {
  background: url('../../assets/images/krap-footer-pr-image.png') no-repeat center center;
  background-size: cover;
  box-sizing: border-box;
  padding: 32px 0 24px;
  text-align: center;
  width: 100%;
  max-width: 768px;
  margin: auto;
}

.McsFooterPRLogo{
  text-align: center;
  width: 100%;
}

.McsFooterPRRead{
  font-size: 1.5rem;
  line-height: 1.6;
  margin-top: 24px;
}

.McsFooterPRButtons{
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  padding: 0 16px;

  li{
    margin: 0 4px;
    // width: 50%;
    width: 100%;
  }
}
