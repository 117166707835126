@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.ChatContentBase{
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.5em;
  padding: 8px;
  max-width: 80%;
  word-break: break-all;
  white-space: pre-wrap;
  a{
    color: inherit;
  }
}

.Other{
  border-radius: 0px 8px 8px 8px;
  background-color: $color-white;
  color: $color-semi-dark;
}

.Myself{
  border-radius: 8px 0px 8px 8px;
  @include gradationColor;
  color: $color-white;
  p{
    text-align: left;
  }
}




