@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.container{
  @include containerWraper;

  padding: 16px;
  padding-top: 48px;
}

.title{
  margin-top: 16px;
  text-align: center;
}

.read{
  color: $color-dark-light;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
}

.readMini{
  color: $color-dark-light;
  font-size: 12px;
  line-height: 150%;
  margin-top: 24px;
  text-align: center;
  white-space: pre-wrap;
}

.product{
  margin-top: 24px;

}

.cardInfo{
  margin-top: 24px;

}

.description{
  margin-top: 32px;

}

.label{
  margin-bottom: 8px;
}


.input{
  margin-top: 24px;
}

.stripeBase{
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 1.6rem;
  height: 100%;
  padding: 0 8px;
  width: 100%;
}

.checkbox{
  margin-top: 24px;
}

.button{
  margin-top: 24px;
}

.editCreditCardButton{
  margin-top: 32px;
}

.InputErrorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
