@import '../../scssUtils//mixins';


.base {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}

.Primary{
  @include gradationColor;

  p{
    color: $color-white;
    font-size: 10px;
  }
}
