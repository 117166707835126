@import '../common/scssUtils/mixins';

.containerWrap{
  @include containerWraper;
}

.title{
  @include contentWrap;

  margin-bottom: 16px;
  margin-top: 24px;
}

.read{
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 24px;

  p{
    color: $color-dark-light;
  }
}

.fileBox{
  margin-bottom: 24px;

}

.inputBox{
  margin-bottom: 24px;

  @include contentWrap;
}

.buttonWrap{
  @include contentWrap;

  margin-top: 32px;
  text-align: center;
}

.button{
  margin-bottom: 16px;
}

.cancel{
  display: inline-block;
}

.buttonMini{
  margin-bottom: 8px;
  margin-right: 8px;
}

.hr{
  background: $color-white-dark;
  border: none;
  display: block;
  margin-top: 0;
}

.errorText{
  color: $color-red;
  font-size: 1.4rem;
  margin-top: 8px;
}
