.McsIconLabelBase {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
  	opacity: 0.7;
  }
}

.McsIconLabelImage {
  margin-right: 8px;
}

.McsIconLabelPrimary {
}

.McsIconLabelSecondary {
}

.McsIconLabelPIcon {
  left: 24px;
  position: absolute;
  top: 24px;
}
