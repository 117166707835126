@import '../../scssUtils/variables';

.OrgFCListBase {
  border-bottom: solid 1px $color-white-dark;
  box-sizing: border-box;
  padding: 16px 0 0;
}


.OrgFCListTitle{
  border-bottom: solid 1px $color-white-dark;
  padding: 0 0 8px 16px;
}

.OrgFCListWrap{
  li{
    border-bottom: solid 1px $color-white-dark;
    padding: 16px;

    &:last-child{
      border-bottom: none;
    }
  }
}
