@import '../../scssUtils/variables';

.OrgNewsUnitBase {
  box-sizing: border-box;
}

.OrgNewsContentContainer{
  border-bottom: 1px solid $color-white-dark;
  display: flex;
  padding: 8px;
}

.OrgNewsContentContainerUnread{
  background-color: #fffcf0;
}

.OrgNewsContentMiddleContainer{
  margin-left: 8px;
  width: calc(100vw - 80px);
}

.OrgNewsContentMainText{
  color: $color-dark-light;
  font-size: 14px;
  font-weight: normal;
  line-height: 130%;

  span{
    font-weight: bold;
  }
}

.OrgNewsContentDateTime{
  color: $color-gray;
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
}

.OrgNewsContentMarkWrapper{
  align-items: center;
  display: flex;
  margin-left: 8px;
}

.OrgNewsContentMark{
  background-color: $color-red;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.OrgNewsContentKRAPIcon{
  border: 1px solid $color-white-dark;
}
