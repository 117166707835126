@import '../../scssUtils/mixins';

.McsInputBase {
  background: $color-white;
  border-top : solid 1px $color-gray-dark ;
  box-sizing: border-box;
  display: flex;
}

.McsInputForm{
  // background: #eee;
  // height: 60px;  //jsで
  // max-height: 150px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  width: 100%;

  div{
    position: relative;
  }

  textarea{
    border: none;
    font-size: 1.6rem;
    height: 100%;
    overflow-y: scroll;
    resize: none;
    width: 100%;
    &::placeholder{
      color: $color-gray-light-ccc;
    }
  }
}

.McsInputButton{
  // background: $color-dark;
  max-width: 60px;
  width: 23%;
  button{
    align-items: center;
    background: none;
    border: none;
    display: flex;
    height: 100%;
    text-align: center;
    width: 100%;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }

  span{
    display: block;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
}

.SubmitButtonGray{
  color: $color-gray-light-ccc;
}

.SubmitButtonGrade{
  @include gradationText;
}
