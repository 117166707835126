@import '../common/scssUtils/mixins';

.container{
  @include container;

  padding-left: 0;
  padding-right: 0;
}

.tabMenuWrap{
  border-bottom: solid 1px $color-white-dark;
  display: flex;
}

.tabMenuItem{
  width: 50%;
}

.title{
  padding-bottom: 16px;
}

.body{
  padding: 16px;
}

.input{
  box-sizing: border-box;
  margin-bottom: 8px;
  margin-top: 24px;
}

.label{
  margin-bottom: 8px;
}

.inputPostal{
  width: 120px;
}

.inputPrice{
  width: 120px;
}

.submit{
  margin-top: 40px;
}

.cancelWrapper{
  margin-top: 8px;
  text-align: center;
  width: 100%;
}

.cancel{
  display: inline-block;
}
