@import '../../scssUtils/variables';

.ThumbnailBase {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;

  img{
    height: auto;
    width: 100%;
  }
}

.ThumbnailPrimary{
  overflow: hidden;
  position: relative;
}

.ThumbnailSizePrimary{
}
