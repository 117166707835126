@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.OrgHeaderFCListBase {
  overflow: hidden;
  padding:  0;
  @include panelBackGround;
  max-width: 768px;
  margin: auto;
  li{
    border-bottom: solid 1px $color-white-dark;
    height: 56px;
    padding-left: 8px;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
    &:last-child{
      border-bottom: none;
    }
  }
}
