@import '../../scssUtils/variables';
@import '../../scssUtils/mixins';

.ButtonBase {
  font-weight: bold;
  text-align: center;
  transition: all .4s ease;
  button {
    border: none;
    height: 100%;
    padding: 0 8px ;
    width: 100%;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}

.ButtonPrimary {
  button {
    color: $color-white;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }

    @include gradationColor;
  }
}

.ButtonSecondary {
  @include gradationColor;

  button {
    background: $color-white;
    color: $color-white;
    height: calc(100% - 4px);
    margin: 2px;
    width: calc(100% - 4px);
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }

    span {
      @include gradationText;

      font-weight: bold;
      white-space: nowrap;
    }
  }
}

.ButtonTertiary {
  @include gradationColor;

  button {
    background: $color-white;
    color: $color-white;
    height: calc(100% - 4px);
    margin: 2px;
    width: calc(100% - 4px);
    cursor: pointer;
    transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }

    span {
      box-sizing: border-box;
      font-weight: bold;
      padding-left: 16px;
      position: relative;

      @include gradationText;

      &::after {
        content: '';
        height: 10px;
        left: 0;
        margin-top: -4px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 10px;

        @include gradationColor;
      }

      &::before {
        background: $color-white;
        content: '';
        height: 10px;
        left: 3px;
        margin-top: -4px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 10px;
        z-index: 2;
      }
    }
  }
}

.ButtonQuaternary {
  button {
    background: none;
    border: solid 1px $color-gray;
    color: $color-gray;
    cursor: pointer;
    transition: all .4s ease;
  &:hover{
    opacity: 0.7;
  }
  }
}

.ButtonQuinary {
  button {
    background: none;
    color: $color-gray;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}

.ButtonSenary {
  button {
    color: $color-white;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }

    @include gradationColorSecondary;
  }
}

.ButtonSeptenary {
  @include gradationColorSecondary;

  button {
    background: $color-white;
    color: $color-white;
    height: calc(100% - 4px);
    margin: 2px;
    width: calc(100% - 4px);
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }

    span {
      @include gradationTextSecondary;

      font-weight: bold;
    }
  }
}

.ButtonOctonary {
  @include gradationColorSecondary;

  button {
    background: $color-white;
    color: $color-white;
    height: calc(100% - 4px);
    margin: 2px;
    width: calc(100% - 4px);
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }

    span {
      box-sizing: border-box;
      font-weight: bold;
      padding-left: 16px;
      position: relative;

      @include gradationTextSecondary;

      &::after {
        content: '';
        height: 10px;
        left: 0;
        margin-top: -4px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 10px;

        @include gradationColorSecondary;
      }

      &::before {
        background: $color-white;
        content: '';
        height: 10px;
        left: 3px;
        margin-top: -4px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 10px;
        z-index: 2;
      }
    }
  }
}

.ButtonNovenary {
  button {
    background: $color-white;
    border: solid 1px $color-blue;
    box-sizing: border-box;
    color: $color-blue;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}

.ButtonDecenary {
  button {
    background: $color-gray;
    box-sizing: border-box;
    color: $color-white;
    cursor: pointer;
    transition: all .4s ease;
    &:hover{
      opacity: 0.7;
    }
  }
}

.ButtonSizePrimary {
  border-radius: 60px;
  font-size: 1.4rem;
  height: 48px;

  button {
    border-radius: 60px;
    font-weight: bold;
  }
}

.ButtonSizeSecondary {
  border-radius: 60px;
  font-size: 1.2rem;
  height: 40px;
  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
    height: 48px;
  }
  button {
    border-radius: 60px;
  }
}

.ButtonSizeTertiary {
  border-radius: 60px;
  font-size: 1.2rem;
  height: 32px;
  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
    height: 40px;
  }
  button {
    border-radius: 60px;
  }
}

.ButtonSizeQuaternary {
  border-radius: 50px;
  font-size: 1.3rem;
  height: 28px;
  @media screen and (min-width: 768px) {
    height: 32px;
  }
  button {
    border-radius: 50px;
  }
}

.ButtonSizeSenary {
  border-radius: 50px;
  font-size: 1.2rem;
  height: 28px;
  @media screen and (min-width: 768px) {
    height: 32px;
  }
  button {
    border-radius: 50px;
    height: calc(100% - 2px);
    margin: 1px;
    width: calc(100% - 2px);
  }
}

.ButtonSizeSeptenary {
  border-radius: 50px;
  font-size: 1.2rem;
  height: 24px;

  button {
    border-radius: 50px;
  }
}

.ButtonSizeOctonary {
  border-radius: 50px;
  font-size: 1.2rem;
  height: 24px;

  button {
    border-radius: 50px;
    height: calc(100% - 2px);
    margin: 1px;
    width: calc(100% - 2px);
  }
}

.ButtonSizeNovenary {
  border-radius: 50px;
  font-size: 1.2rem;
  height: 20px;

  button {
    border-radius: 50px;
  }
}
