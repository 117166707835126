@import '../common/scssUtils/mixins';
@import '../common/scssUtils/variables';

.container{
  box-sizing: border-box;
  height: 100%;
  margin-top: 48px;
  padding: 16px;
  width: 100%;
}

.title{
  color: $color-gray;
  font-family: 'Roboto Condensed',$sans-serif;
  font-size: 128px;
  font-weight: bold;
  text-align: center;
}

.subTitle{
  color: $color-gray;
  font-family: Roboto,$sans-serif;
  font-size: 24px;
  letter-spacing: 0.04em;
  margin-top: 8px;
  text-align: center;
}

.summary{
  font-family: $sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
  text-align: center;
}

.description{
  color: $color-dark-light;
  font-family: $sans-serif;
  font-size: 14px;
  line-height: 150%;
  margin-top: 16px;
  white-space: pre-wrap;
}

.button{
  margin-bottom: 40px;
  margin-top: 40px;
}
