.OrgUserListUnitBase {
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  transition: all .4s ease;
  &:hover{
  	opacity: 0.7;
  }
}

.OrgUserListUnitUser{
  align-items: center;
  display: flex;
  justify-content: space-between;
}
