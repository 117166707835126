@import '../common/scssUtils/mixins';

.container{
  @include containerWraper;

  padding-bottom: 0;
}

.content{
  border-top: 1px solid $color-white-dark;
  padding: 16px;
}
