@import '../../scssUtils/variables';

.McsIconLabelBase {
  width: 72px;
}

.McsIconLabelText{
  color: $color-gray;
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.McsIconLabelPrimary{
}
