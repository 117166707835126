@import '../../scssUtils/variables';

.container {
  align-items: center;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.title{
  font-size: 14px;
  font-weight: bold;
  margin: 0 8px;
}

.read{
  color: $color-gray;
  font-size: 12px;
  line-height: 150%;
  margin: 8px 8px 0;
  white-space: pre-wrap;
}

.buttonMargin{
  margin: 16px 0;
}

.buttonMarginTop{
  margin: 16px 0 0;
}

.crossWrapper{
  align-items: center;
  background-color: $color-gray;
  border-bottom-left-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}
