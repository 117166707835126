@import '../../scssUtils/variables';

.OrgTwitterShareButtonBase {
  background: #444;
  box-sizing: border-box;
  padding: 4px;
  width: 100%;
}

.OrgTwitterShareButtonText {
  color: $color-white;
  font-size: 1rem;
}

.OrgTwitterShareButtonButton {
  align-items: center;
  background-color: $color-twitter;
  border: none;
  border-radius: 8px;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-top: 4px;
  width: 100%;

  p{
    color: $color-white;
    font-size: 1.6rem;
    margin-left: 8px;
  }
}
