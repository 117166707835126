//color
$color-dark: #000;
$color-semi-dark: #333;
$color-dark-light : #666;

$color-white: #fff;
$color-white-dark: #eee;

$color-red :#eb5757;
$color-red-light: #ffe3e3;
$color-orange: #ff8d23;

$color-blue :#1156db;
$color-blue-light: #11abdb;


$color-gray: #96a9bc;
$color-gray-light: #f6f6f6;
$color-gray-light-ddd: #ddd;
$color-gray-light-ccc: #ccc;
$color-gray-dark: #999;



$color-bg: #ffe;

$color-twitter :  #1da1f2;
$color-facebook : #4267b2;
$color-line: #3aae37;

$sans-serif: 'Noto Sans JP',-apple-system, 'San Francisco', BlinkMacSystemFont,
  'Helvetica Neue', 'Hiragino Sans', Meiryo, 'MS PGothic', sans-serif;

$serif: Georgia, 'Hiragino Mincho', serif;



